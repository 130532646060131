import { BrowserRouter } from "react-router-dom";
import Router from './components/Router/Router';

import './i18n/i18n';

import './App.css';
import ToastContextProvider from "./contexts/ToastContext";


function App() {
  return (
    <ToastContextProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
        <Router />
      </BrowserRouter>
    </ToastContextProvider>
  );
}

export default App;
