import React from "react";
import { useTranslation } from "react-i18next";
import { supportedFileExtensions } from "../../utils/constants";
import classes from './MediaToAdd.module.css';

import ADD_ICON from "../../assets/images/add.svg";
import REMOVE_ICON from "../../assets/images/delete.svg";
import FILE_ICON from "../../assets/images/file.svg";
import DOCUMENT_ICON from "../../assets/images/file.svg";
import DOCUMENT_AUDIO_ICON from "../../assets/images/file.svg";
import DOCUMENT_VIDEO_ICON from "../../assets/images/file.svg";


const MediaToAdd = React.forwardRef((props, ref) => {
  
  const {
    sourceUuid,
    mediaIndex,
    media,
    onEditMedia,
    onRemoveMedia
  } = props;

  const mediaTitleRef = React.useRef();
  const mediaFileRef = React.useRef();
  const inputFileRef = React.useRef();
  
  const { t } = useTranslation();

  const [fileSizeAlert, setFileSizeAlert] = React.useState(false);
  
  const handleSetMediaTitle = React.useCallback((title) => {
    const newMedia = { ...media };
    newMedia.title = title;
    onEditMedia(newMedia)
  }, [media, onEditMedia])
  
  const handleSetMediaFile = React.useCallback((file, result) => {
    const newMedia = { ...media };
    newMedia.file = file;
    newMedia.fileData = result;
    onEditMedia(newMedia)
  }, [media, onEditMedia]);

  const handleRemoveMediaFile = React.useCallback(() => {
    const newMedia = { ...media };
    newMedia.file = null;
    newMedia.fileData = null;
    onEditMedia(newMedia)
  }, [media, onEditMedia]);

  const previewFile = React.useCallback(() => {
    const file = inputFileRef.current.files[0];

    console.log("file Size", file.size);
    if(file.size < 2*1024*1024){

      const reader = new FileReader();
      reader.addEventListener("load", function () {
        // convert file to base64 string
        handleSetMediaFile(file, reader.result);
      }, false);
    
      if (file) {
        reader.readAsDataURL(file);
      }

    }
    else{
      setFileSizeAlert(true);
    }

    
  }, [handleSetMediaFile]);

  const setImageBasedOnSourceFile = React.useCallback(() => {
    if(media.fileData){
      if(media.fileData.includes(supportedFileExtensions.IMAGE)){
        return media.fileData;
      }
      else if(media.fileData.includes(supportedFileExtensions.AUDIO)){
        return DOCUMENT_AUDIO_ICON
      }
      else if(media.fileData.includes(supportedFileExtensions.VIDEO)){
        return DOCUMENT_VIDEO_ICON
      }
      else if(media.fileData.includes(supportedFileExtensions.TEXT)){
        return DOCUMENT_ICON
      }
      else{
        return DOCUMENT_ICON
      }
    }
  }, [media]);

  const setImageBasedOnExistingData = React.useCallback(() => {
    if(media.mediaType && media.mediaUrl){
      const type = "data:"+media.mediaType;
      if(type.includes(supportedFileExtensions.IMAGE)){
        return media.mediaUrl;
      }
      else if(type.includes(supportedFileExtensions.AUDIO)){
        return DOCUMENT_AUDIO_ICON
      }
      else if(type.includes(supportedFileExtensions.VIDEO)){
        return DOCUMENT_VIDEO_ICON
      }
      else if(type.includes(supportedFileExtensions.TEXT)){
        return DOCUMENT_ICON
      }
      else{
        return DOCUMENT_ICON
      }
    }
  }, [media]);

  React.useImperativeHandle(ref, () => ({

    checkData(m) {
      console.log(m);
      let errorsFound = 0;
      if(!m.id){
        if(!m.title){
          mediaTitleRef.current.classList.add(classes.InputError);
          errorsFound++;
        }
        if(!m.file){
          mediaFileRef.current.classList.add(classes.InputError);
          errorsFound++;
        }
      }
      return errorsFound === 0;
    }

  }));


  return (
    <div className={classes.Container}>
      <div className={classes.TitleRow}>
        <input 
          ref={mediaTitleRef}
          className={classes.TitleInput}
          type="text" 
          value={media.title}
          placeholder={t("author.addSources.insertMediaTitle")} 
          onFocus={() => { mediaTitleRef.current.classList.remove(classes.InputError); }}
          onChange={(event) => handleSetMediaTitle(event.target.value)}  
        />
        <img 
          className={classes.RemoveMediaIcon} 
          onClick={() => onRemoveMedia()}
          src={REMOVE_ICON} 
          alt="" 
        />
      </div>
      <div className={classes.FileInputRow}>
        { 
          !media.id && media.file &&
          <div className={classes.MediaInputContainer}>
            <img src={FILE_ICON} alt="" className={classes.MediaTypeIcon} />
            <div className={[classes.MediaInput, classes.MediaFileInput].join(" ")}>
              <p className={classes.MediaFileText} title={media.file.name}>{media.file.name}</p>
            </div>
            <p className={classes.DeleteMediaIcon} onClick={() => handleRemoveMediaFile()}>X</p>
          </div>
        }
        {
          !media.id && !media.file &&
          <div className={classes.AddMediaContainer}>
            <label 
              htmlFor={`${sourceUuid}-${mediaIndex}-media-files`} 
              className={classes.AddMediaText} 
              onClick={() => {
                setFileSizeAlert(false);
                mediaFileRef.current.classList.remove(classes.InputError);
              }}
            >
              <img className={classes.SetMediaFileIcon} src={ADD_ICON} alt="" /> {t('author.addSources.selectMedia')}
            </label>
            <input
              ref={inputFileRef}
              type="file"
              id={`${sourceUuid}-${mediaIndex}-media-files`}
              style={{ visibility: 'hidden', width: 0 }}
              onChange={() => previewFile()}
            />
          </div>
        }
        {
          !media.id && !media.file && fileSizeAlert &&
          <p className={classes.FileSizeAlertText}>La dimensione del file non può superare i 2MB</p>
        }
        { 
          media.id &&
          <div className={classes.MediaInputContainer}>
            <img src={FILE_ICON} alt="" className={classes.MediaTypeIcon} />
            <div className={[classes.MediaInput, classes.MediaFileInput].join(" ")}>
              <p className={classes.MediaFileText} title={media.mediaName}>{media.mediaName}</p>
            </div>
            <p className={classes.DeleteMediaIcon} onClick={() => handleRemoveMediaFile()}>X</p>
          </div>
        }
      </div>
      <div className={classes.PreviewColumn} ref={mediaFileRef}>          
        { 
          !media.id && media.file &&
          <img src={setImageBasedOnSourceFile()} className={classes.PreviewImage} alt=""/>
        }
        {
          !media.id && !media.file &&
          <div className={classes.NoMediaAdded}>
            <p className={classes.NoMediaAddedText}>{t("author.addSources.selectFile")}</p>
          </div>
        }
        {
          media.id &&
          <img src={setImageBasedOnExistingData()} className={classes.PreviewImage} alt=""/>
        }
      </div>
    </div>
  );

});

export default MediaToAdd;