import React from "react";

import classes from './AddNarration.module.css';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createJSONObject, createJSONObjectForEdit, getEntitiesArrayData, getNarrationData, getSourcesArrayData } from "../../../utils/omekaSManager";

import { ArticleClass, commonProperties, ParagraphClass, SourceReferenceClass } from "../../../utils/constants";

import AuthorComponent from "../../../components/AuthorComponent/AuthorComponent";
import { UserContext } from "../../../contexts/UserContext";

const axios = require('axios').default;


const AddNarration = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData, userLogged } = React.useContext(UserContext);
  const [data, setData] = React.useState([]);

  const loadData = React.useCallback(async (classes, properties, _, newArticleData, alreadyExistingEntities, alreadyExistingSources) => {
    
    const feedback = [];

    try{
    
      const body = createJSONObject(classes, properties, newArticleData.title, newArticleData.narrator, newArticleData.insertedFragments, newArticleData.insertedEntities, newArticleData.insertedRelations, newArticleData.insertedSources, alreadyExistingEntities, alreadyExistingSources);
      console.log("adding stuff", body);

      const addedSourcesOmekaS = [];
      const addedSourceReferencesOmekaS = [];
      const addedEntitiesOmekaS = [];

      //adding sources elements
      for(const sourceData of body.sourcesToAdd){
        try {
          const formData = new FormData();
          formData.append("data", JSON.stringify(sourceData.json));
          for(const [i, f] of Object.entries(sourceData.filesArray)){
            const index = parseInt(i);
            formData.append(`file[${index}]`, f);
          }
          const { data } = await axios.post("/api/itemsWithMedia", formData, { headers: { "Content-Type": `multipart/form-data; boundary=${formData._boundary}` }});
          console.log("adding stuff - SOURCES ok", data);
          feedback.push("OK");
          addedSourcesOmekaS.push({ uuid: sourceData.uuid, omekaSId: data[commonProperties.ID] });
        }
        catch(error){
          console.log("adding stuff - SOURCES error", error);
          feedback.push("ERROR");
        };
      }

      //adding source references elements
      for(const sourceRefsData of body.sourceReferencesToAdd){
        try {
          const json = { ...sourceRefsData.json };
          if(json[SourceReferenceClass.properties.references] && json[SourceReferenceClass.properties.references].length > 0){
            for(let i=0; i<json[SourceReferenceClass.properties.references].length; i++){
              const sourceUuid = json[SourceReferenceClass.properties.references][i];
              const sourceIndex1 = addedSourcesOmekaS.findIndex(el => { return el.uuid === sourceUuid });
              const sourceIndex2 = alreadyExistingSources.findIndex(el => { return el.uuid === sourceUuid });
              if(sourceIndex1 !== -1){
                json[SourceReferenceClass.properties.references][i] = {
                  [commonProperties.PROPERTY_ID]: sourceRefsData.referencesID,
                  [commonProperties.VALUE_RESOURCE_ID]: addedSourcesOmekaS[sourceIndex1].omekaSId,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              }
              else if(sourceIndex2 !== -1){
                json[SourceReferenceClass.properties.references][i] = {
                  [commonProperties.PROPERTY_ID]: sourceRefsData.referencesID,
                  [commonProperties.VALUE_RESOURCE_ID]: alreadyExistingSources[sourceIndex2].id,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              }
              else{}
            }
          }
          const { data } = await axios.post("/api/items", json);
          console.log("adding stuff - SOURCE REFERENCES ok", data);
          feedback.push("OK");
          addedSourceReferencesOmekaS.push({ uuid: sourceRefsData.uuid, omekaSId: data[commonProperties.ID]});
        }
        catch(error){
          console.log("adding stuff - SOURCE REFERENCES error", error);
          feedback.push("ERROR");
        };
      }
    
      //adding knowledge elements
      for(const entData of body.entitiesToAdd){
        if(entData.action === "ADD"){
          try {
            const { data } = await axios.post("/api/items", entData.json);
            console.log("adding stuff- ENTITIES ok", data);
            feedback.push("OK");
            addedEntitiesOmekaS.push({uuid: entData.uuid, omekaSId: data[commonProperties.ID] });
          }
          catch(error){
            console.log("adding stuff - ENTITIES error", error);
            feedback.push("ERROR");
          };
        }
        else if(entData.action === "EDIT"){
          try {
            const { data } = await axios.patch("/api/items"+entData.id, entData.json);
            console.log("editing stuff- ENTITIES ok", data);
            feedback.push("OK");
            //addedEntitiesOmekaS.push({uuid: entData.uuid, omekaSId: entData.id });
          }
          catch(error){
            console.log("editing stuff - ENTITIES error", error);
            feedback.push("ERROR");
          };
        }
        else{}
      };

      //updating knowledge elements with relations
      for(const r of body.relationsToAdd){
        const fromEntityIndex1 = addedEntitiesOmekaS.findIndex(el => { return el.uuid === r.from });
        const fromEntityIndex2 = alreadyExistingEntities.findIndex(el => { return el.uuid === r.from });
        const toEntityIndex1 = addedEntitiesOmekaS.findIndex(el => { return el.uuid === r.to });
        const toEntityIndex2 = alreadyExistingEntities.findIndex(el => { return el.uuid === r.to });
        let fromEntityId;
        let toEntityId;
        if(fromEntityIndex1 !== -1){
          fromEntityId = addedEntitiesOmekaS[fromEntityIndex1].omekaSId;
        }
        else if(fromEntityIndex2 !== -1){
          fromEntityId = alreadyExistingEntities[fromEntityIndex2].id;
        }
        else{}

        if(toEntityIndex1 !== -1){
          toEntityId = addedEntitiesOmekaS[toEntityIndex1].omekaSId;
        }
        else if(toEntityIndex2 !== -1){
          toEntityId = alreadyExistingEntities[toEntityIndex2].id;
        }
        else{}
        
        if(fromEntityId && toEntityId){
          try{
            const { data: eData } = await axios.get("/api/items/"+fromEntityId);
            const json = { ...eData };
            if(json[r.relation]){
              json[r.relation].push(
                { 
                  [commonProperties.PROPERTY_ID]: r.relationId,
                  [commonProperties.VALUE_RESOURCE_ID]: toEntityId,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              )
            }
            else{
              json[r.relation] = [
                { 
                  [commonProperties.PROPERTY_ID]: r.relationId,
                  [commonProperties.VALUE_RESOURCE_ID]: toEntityId,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              ]
            }
            const { data } = await axios.patch("/api/items/"+fromEntityId, json);
            console.log("adding stuff- RELATIONS ok", data);
            feedback.push("OK");
          }
          catch(error){
            console.log("adding stuff - RELATIONS error", error);
            feedback.push("ERROR");
          }
        } 
      }
    
      const addedParagraphsOmekaSIDs = [];

      //adding narration elements (paragraphs) 
      for(const fragData of body.fragmentsToAdd){
        try {
          const json = { ...fragData.json };
          if(json[ParagraphClass.properties.sourceReference] && json[ParagraphClass.properties.sourceReference].length > 0){
            for(let i=0; i<json[ParagraphClass.properties.sourceReference].length; i++){
              const sourceUuid = json[ParagraphClass.properties.sourceReference][i];
              const sourceIndex = addedSourceReferencesOmekaS.findIndex(el => { return el.uuid === sourceUuid });
              if(sourceIndex !== -1){
                json[ParagraphClass.properties.sourceReference][i] = {
                  [commonProperties.PROPERTY_ID]: fragData.sourceRefPropertyId,
                  [commonProperties.VALUE_RESOURCE_ID]: addedSourceReferencesOmekaS[sourceIndex].omekaSId,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              }
            }
          }
          if(json[ParagraphClass.properties.knowledgeEncoding] && json[ParagraphClass.properties.knowledgeEncoding].length > 0){
            for(let i=0; i<json[ParagraphClass.properties.knowledgeEncoding].length; i++){
              const entityUuid = json[ParagraphClass.properties.knowledgeEncoding][i];
              const entIndex1 = addedEntitiesOmekaS.findIndex(el => { return el.uuid === entityUuid });
              const entIndex2 = alreadyExistingEntities.findIndex(el => { return el.uuid === entityUuid });
              if(entIndex1 !== -1){
                json[ParagraphClass.properties.knowledgeEncoding][i] = {
                  [commonProperties.PROPERTY_ID]: fragData.knowledgeEncodingPropertyId,
                  [commonProperties.VALUE_RESOURCE_ID]: addedEntitiesOmekaS[entIndex1].omekaSId,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              }
              else if(entIndex2 !== -1){
                json[ParagraphClass.properties.knowledgeEncoding][i] = {
                  [commonProperties.PROPERTY_ID]: fragData.knowledgeEncodingPropertyId,
                  [commonProperties.VALUE_RESOURCE_ID]: alreadyExistingEntities[entIndex2].id,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                }
              }
              else{}
            }
          }
          const { data } = await axios.post("/api/items", json);
          console.log("adding stuff - PARAGRAPHS ok", data);
          feedback.push("OK");
          addedParagraphsOmekaSIDs.push(data[commonProperties.ID]);
          body.narrationObj[fragData.property] = [{ 
            [commonProperties.PROPERTY_ID]: fragData.propertyID,
            [commonProperties.VALUE_RESOURCE_ID]: data[commonProperties.ID],
            [commonProperties.RESOURCE_TYPE]: "resource"
          }];
        }
        catch(error){
          console.log("adding stuff - PARAGRAPHS error", error);
          feedback.push("ERROR");
        };
      }

      //updating narration elements (paragraphs) previous and next
      for(const [i, fragData] of Object.entries(body.fragmentsToAdd)){
        try {
          const index = parseInt(i);
          const json = {
            ...fragData.json,
          };
          if(index===0){
            //set NEXT
            json[ParagraphClass.properties.next] = [{
              [commonProperties.PROPERTY_ID]: fragData.nextPropertyId,
              [commonProperties.VALUE_RESOURCE_ID]: addedParagraphsOmekaSIDs[index+1],
              [commonProperties.RESOURCE_TYPE]: "resource"
            }]
          }
          else if(index > 0 && index < body.fragmentsToAdd.length - 1){
            //set PREVIOUS
            json[ParagraphClass.properties.previous] = [{
              [commonProperties.PROPERTY_ID]: fragData.previousPropertyId,
              [commonProperties.VALUE_RESOURCE_ID]: addedParagraphsOmekaSIDs[index-1],
              [commonProperties.RESOURCE_TYPE]: "resource"
            }];
            //set NEXT
            json[ParagraphClass.properties.next] = [{
              [commonProperties.PROPERTY_ID]: fragData.nextPropertyId,
              [commonProperties.VALUE_RESOURCE_ID]: addedParagraphsOmekaSIDs[index+1],
              [commonProperties.RESOURCE_TYPE]: "resource"
            }]
          }
          else{
            //set PREVIOUS
            json[ParagraphClass.properties.previous] = [{
              [commonProperties.PROPERTY_ID]: fragData.previousPropertyId,
              [commonProperties.VALUE_RESOURCE_ID]: addedParagraphsOmekaSIDs[index-1],
              [commonProperties.RESOURCE_TYPE]: "resource"
            }]
          }
          const { data } = await axios.patch("/api/items/"+addedParagraphsOmekaSIDs[index], json);
          console.log("updating stuff- PARAGRAPHS ok", data);
          feedback.push("OK");
        }
        catch(error){
          console.log("updating stuff - PARAGRAPHS error", error);
          feedback.push("ERROR");
        };
      }

      let addedNarratorItemOmekaSId;
      let narratorPropertyId;
    
      //adding narrator fragment item
      if(body.narratorToAdd){
        try {
          narratorPropertyId = body.narratorToAdd.propertyId;
          const { data } = await axios.post("/api/items", body.narratorToAdd.json);
          console.log("adding stuff- NARRATOR ok", data);
          feedback.push("OK");
          addedNarratorItemOmekaSId = data[commonProperties.ID];
        }
        catch(error){
          console.log("adding stuff - NARRATOR error", error);
          feedback.push("ERROR");
        };
      }

      //adding narration element
      try {
        const json = { ...body.narrationObj };
        if(addedNarratorItemOmekaSId && narratorPropertyId){
          json[ArticleClass.properties.narrator] = [
            {
              [commonProperties.PROPERTY_ID] : narratorPropertyId,
              [commonProperties.VALUE_RESOURCE_ID] : addedNarratorItemOmekaSId,
              [commonProperties.RESOURCE_TYPE] : "resource"
            }
          ];
        }
        const { data } = await axios.post("/api/items", json);
        console.log("adding stuff - NARRATION ok", data);
        feedback.push("OK");
      }
      catch(error){
        console.log("adding stuff - NARRATION error", error);
        feedback.push("ERROR");
      };

    }
    catch(e){
      console.log("Error in creating or saving data", e);
      feedback.push("ERROR");
    }

    return feedback;
  
  }, []);

  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);
  
  React.useEffect(() => {
    if(userData){
      if(userLogged){
        axios.get("/api/classesAndProperties").then(
          (res) => {
            console.log("OK CLASSES AND PROPERTIES", res.data);
            const d = { ...res.data };
            axios.get("/api/data").then(
              (r) => {
                console.log("OK Data", r.data);
                d.alreadyExistingEntities = getEntitiesArrayData(r.data.items, r.data.media);
                d.alreadyExistingSources = getSourcesArrayData(r.data.items, r.data.media);
                setData(d);
              },
              (e) => {
                console.log("ERROR on retriving data", e);
              }
            );
          },
          (error) => {
            console.log("ERROR on retriving classes and properties", error);
          }
        );
      }
      else{
        redirectTo("/visualization/narrations");
      }
    }

  }, [id, userData, userLogged, redirectTo]);

  return (
    <AuthorComponent 
      isEdit={false}
      data={data}
      saveData={(classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources) => loadData(classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources)}
    />
  );
};

export default AddNarration;