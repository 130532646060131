import React from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import ADD_ICON from '../../assets/images/add.svg';
import REMOVE_ICON from '../../assets/images/remove.svg';
import DELETE_ICON from '../../assets/images/delete.svg';

import classes from './Fragment.module.css';
import Dropdown from "../Dropdown/Dropdown";
import { getCommentFromClassOrProperty, getFragmentFromLabel } from "../../utils/omekaSManager";


const Fragment = (props) => {
  
  const {
    existingSources,
    insertedSources,
    fragment,
    fragmentToEdit,
    onAddFragment,
    onDeleteEdit,
    omekaSClasses,
    omekaSProperties
  } = props;

  const textInputRef = React.useRef();
  const sourceReferencesTitleRefs = React.useRef([]);
  const sourceReferencesTextRefs = React.useRef([]);
  sourceReferencesTitleRefs.current = [];
  sourceReferencesTextRefs.current = [];

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(fragmentToEdit ? true : false);

  const [text, setText] = React.useState(fragmentToEdit ? fragmentToEdit.text : "");
  const [sourceReferences, setSourceReferences] = React.useState(fragmentToEdit && fragmentToEdit.sourceReferences ? [...fragmentToEdit.sourceReferences.slice()] : []);
  const [currentSource, setCurrentSource] = React.useState();

  const setSourceArrayForDropdown = React.useCallback((sourceRefIndex) => {
    const selectedSourcesUuids = sourceReferences[sourceRefIndex] && sourceReferences[sourceRefIndex].sources && sourceReferences[sourceRefIndex].sources.length > 0 ? sourceReferences[sourceRefIndex].sources : [];
    return insertedSources
      .concat(existingSources)
      .filter(s => { return !selectedSourcesUuids.includes(s.uuid) })
      .map(s => { 
        return {
          label: s.title,
          value: s
        }
      })
  }, [insertedSources, sourceReferences, existingSources]);

  const findSourceIndexValue = React.useCallback((source, sourceRefIndex) => {
    if(source && source.sourceRefIndex === sourceRefIndex){
      const items = setSourceArrayForDropdown();
      for(const [index, s] of Object.entries(items)){
        if(source.value === s.value){
          return parseInt(index);
        }
      }
    }
    return -1;
  }, [setSourceArrayForDropdown]);
  
  const handleAddSourceReference = React.useCallback(() => {
    const items = Array.from(sourceReferences);
    items.push({ title: "", text: "", sources: [] });
    setSourceReferences(items);
  }, [sourceReferences]);

  const handleDeleteSourceReference = React.useCallback((index) => {
    const items = Array.from(sourceReferences);
    items.splice(index, 1);
    setSourceReferences(items);
  }, [sourceReferences]);

  const handleSetSourceReferenceTitle = React.useCallback((sourceRefIndex, title) => {
    const items = Array.from(sourceReferences);
    items[sourceRefIndex] = {
      ...items[sourceRefIndex],
      title: title
    };
    setSourceReferences(items);
  }, [sourceReferences]);

  const handleSetSourceReferenceText = React.useCallback((sourceRefIndex, text) => {
    const items = Array.from(sourceReferences);
    items[sourceRefIndex] = {
      ...items[sourceRefIndex],
      text: text
    }
    setSourceReferences(items);
  }, [sourceReferences]);

  const handleAddSource = React.useCallback((sourceRefIndex) => {
    if(currentSource){
      const items = Array.from(sourceReferences);
      const newSources = [ ...items[sourceRefIndex].sources ];
      newSources.push(currentSource.value.uuid);
      items[sourceRefIndex] = { 
        ...items[sourceRefIndex],
        sources: [...newSources]
      };
      setSourceReferences(items);
      setCurrentSource();
    }
  }, [sourceReferences, currentSource]);

  const handleDeleteSource = React.useCallback((sourceRefIndex, sourceIndex, event) => {
    event.preventDefault();
    event.stopPropagation();
    const items = Array.from(sourceReferences);
    const newSources = [ ...items[sourceRefIndex].sources ];
    newSources.splice(sourceIndex, 1);
    items[sourceRefIndex] = {
      ...items[sourceRefIndex],
      sources: newSources
    };
    setSourceReferences(items);
  }, [sourceReferences]);



  const checkSources = React.useCallback(() => {
    for(const [i, source] of Object.entries(sourceReferences)){
      const index = parseInt(i);
      if(!source.title){
        return index;
      }
    }
    return null;
  }, [sourceReferences]);



  const addSourceReferenceTitleRef = React.useCallback((ref) => {
    if(ref && !sourceReferencesTitleRefs.current.includes(ref)){
      sourceReferencesTitleRefs.current.push(ref);
    }
  }, [sourceReferencesTitleRefs]);

  const addSourceReferenceTextRef = React.useCallback((ref) => {
    if(ref && !sourceReferencesTextRefs.current.includes(ref)){
      sourceReferencesTextRefs.current.push(ref);
    }
  }, [sourceReferencesTextRefs]);

  const handleAddFragment = React.useCallback(() => {
    const newFragment = {
      id: fragmentToEdit ? fragmentToEdit.id : null,
      uuid: fragmentToEdit ? fragmentToEdit.uuid : v4(),
      fragment: fragment,
      text: text,
      sourceReferences: sourceReferences
    }

    const sourceNotFilledIndex = checkSources();

    if(text && !sourceNotFilledIndex){
      onAddFragment(newFragment);
      setText("");
      setSourceReferences([]);
      setOpen(false);
    }
    else{
      if(!text){
        textInputRef.current.classList.add(classes.InputError);
      }
      else if(sourceNotFilledIndex){
        sourceReferencesTitleRefs.current[sourceNotFilledIndex].classList.add(classes.InputError);
      }
    }
  }, [fragmentToEdit, fragment, text, sourceReferences, checkSources, onAddFragment, textInputRef, sourceReferencesTitleRefs]);
  
  const getSourceDataFromUuid = React.useCallback((uuid) => { 
    let sourceObj;
    for(const s of insertedSources){
      if(s.uuid === uuid){
        sourceObj = s;
      }
    }
    for(const s of existingSources){
      if(s.uuid === uuid){
        sourceObj = s;
      }
    }
    return sourceObj ? sourceObj : null;
  }, [existingSources, insertedSources]);

  const getFragmentClass = React.useCallback((fragmentLabel) => {
    const fragmentObj = getFragmentFromLabel(fragmentLabel);
    return fragmentObj && fragmentObj.class ? fragmentObj.class : null;
  }, []);

  return (
    <div 
      className={[classes.Fragment, open ? classes.OpenFragment : classes.ClosedFragment].join(" ")}
    >
      <div className={classes.HeaderContainer}>
        <p
          title={getCommentFromClassOrProperty(omekaSClasses, getFragmentClass(fragment))} 
          className={classes.FragmentTitle} 
          onClick={() => setOpen(!open)}
        >
          {t('general.fragments.'+fragment)}
        </p>
        <img src={open ? REMOVE_ICON : ADD_ICON} alt="" className={classes.OpenCloseIcon} onClick={() => setOpen(!open)}/>
      </div>
      { open && 
        <div className={classes.OpenFragmentContainer}>
          <textarea 
            ref={textInputRef}
            rows="4"
            type="text"
            value={text}
            className={classes.Input}
            placeholder={t("author.addNarration.fragment.insertParagraphPlaceholder", { context: "capFirst" })}
            onChange={(event) => setText(event.target.value)}
            onFocus={() => { textInputRef.current.classList.remove(classes.InputError) }}
          />
          <p className={classes.SourceReferencesTitle}>{t("author.addNarration.fragment.sourceReferences")}</p>
          <p className={classes.SourceReferencesTextInfo}>{t("author.addNarration.fragment.sourceReferenceInfoText")}&nbsp;<span>{t("author.addNarration.fragment.sourceSection")}</span></p>
          {
            sourceReferences && sourceReferences.map((s, sourceRefIndex) => {
              return (
                <div key={fragment+"-source-reference-"+sourceRefIndex} className={classes.SourceReferenceContainer}>
                  <div className={classes.SourceReferenceInfoColumn}>
                    <input 
                      type="text"
                      value={s.title}
                      placeholder={t("author.addNarration.fragment.insertSourceReferenceTitle")}
                      ref={(ref) => addSourceReferenceTitleRef(ref)}
                      className={classes.SourceReferenceInput}
                      onFocus={() => { sourceReferencesTitleRefs.current[sourceRefIndex].classList.remove(classes.InputError); }}
                      onChange={(event) => handleSetSourceReferenceTitle(sourceRefIndex, event.target.value)}
                    />
                    <textarea 
                      value={s.text}
                      rows="3"
                      placeholder={t("author.addNarration.fragment.insertSourceReferenceText")}
                      ref={(ref) => addSourceReferenceTextRef(ref)}
                      className={classes.SourceReferenceTextarea}
                      onFocus={() => { sourceReferencesTitleRefs.current[sourceRefIndex].classList.remove(classes.InputError); }}
                      onChange={(event) => handleSetSourceReferenceText(sourceRefIndex, event.target.value)}
                    />
                    <div className={classes.RemoveSourceReferenceContainer}>
                      <div className={classes.ClickableAreaRow} onClick={() => handleDeleteSourceReference(sourceRefIndex)}>
                        <img className={classes.RemoveSourceReferenceIcon} src={DELETE_ICON} alt="" />
                        <p className={classes.RemoveSourceReferenceText}>{t("author.addNarration.fragment.removeSourceReference")}</p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.AddSourcesColumn}>
                    <div className={classes.AddSourceRow}>
                      <p className={classes.AddSourceText}>{t('author.addNarration.fragment.sources')}</p>
                      <div className={classes.AddSourceDropdown}>
                        <Dropdown
                          id={`${fragment}-source-reference-${sourceRefIndex}-select-source`}
                          placeholder={t("author.addNarration.fragment.selectSource")}
                          value={findSourceIndexValue(currentSource, sourceRefIndex)}
                          elements={setSourceArrayForDropdown(sourceRefIndex)}
                          onSelectValue={(value) => setCurrentSource({ value: value, sourceRefIndex: sourceRefIndex })}
                        />
                      </div>
                      <p 
                        className={
                          currentSource && currentSource.sourceRefIndex === sourceRefIndex ? 
                            [classes.AddSourceIcon, classes.Allowed].join(" ") : 
                            [classes.AddSourceIcon, classes.Disabled].join(" ")
                        }
                        onClick={() => handleAddSource(sourceRefIndex)}
                      >&#10004;</p>
                    </div>
                    <div className={classes.AddedSources}>
                      {
                        s.sources.map((sourceUuid, sourceIndex) => {
                          const sourceData = getSourceDataFromUuid(sourceUuid);
                          if(sourceData){
                            return (
                              <div key={fragment+"-source-reference-"+sourceIndex+"-source-"+sourceIndex} className={classes.AddedSourceContainer}>
                                <p className={classes.AddedSourceText}>&#9679; {sourceData.title}</p>
                                <img 
                                  src={DELETE_ICON} 
                                  alt="" 
                                  className={classes.RemoveSourceIcon} 
                                  onClick={(event) => handleDeleteSource(sourceRefIndex, sourceIndex, event)}/>
                              </div>
                            )
                          }
                          else{
                            return null;
                          }
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
          <div className={classes.AddSourceReferenceContainer}>
            <div className={classes.ClickableAreaRow}  onClick={() => handleAddSourceReference()}>
              <img src={ADD_ICON} alt="" className={classes.AddSourceReferenceIcon}/>
              <p className={classes.AddSourceReferenceText}>{t("author.addNarration.fragment.addSourceReference")}</p>
            </div>
          </div>
          <div className={classes.DoneContainer}>
            { 
              fragmentToEdit && 
              <div className={classes.ClickableAreaEditFragment}>
                <p className={classes.CancelEditText} onClick={() => onDeleteEdit()}>{t('author.addNarration.fragment.cancel', { context: 'capFirst' })}</p>
                <p className={classes.ConfirmEditText} onClick={() => handleAddFragment()}>&#10004; {t('author.addNarration.fragment.substitute', { context: 'capFirst' })}</p>
              </div>
            }
            {
              !fragmentToEdit &&
              <div className={classes.ClickebleArea} onClick={() => handleAddFragment()}>
                <p>&#10004;{/*t('author.addNarration.fragment.done', { context: 'capFirst' })*/}</p> 
              </div>
            }
          </div>
        </div>
      }
    </div>
  );

};

export default Fragment;