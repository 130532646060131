import React, { forwardRef, useImperativeHandle } from "react";
import LeafletMap from "../LeafletMap/LeafletMap";

import classes from './Map.module.css';
import { useTranslation } from "react-i18next";

import L from 'leaflet';

const sortLocationsByCoordinates = (a, b) => {
  if(a.location.lat < b.location.lat){
    return -1;
  }
  else if(a.location.lat > b.location.lat){
    return 1;
  }
  else{
    if(a.location.lng < b.location.lng){
      return -1;
    }
    else if(a.location.lng > b.location.lng){
      return 1;
    }
    else{
      return 0;
    }
  }
};

const Map = forwardRef((props, ref) => {

  const {
    narrations,
    onSelectNarration,
  } = props;

  useImperativeHandle(ref, () => ({

    centerMap(locs) {
      const coords = locs.map((el) => { return L.latLng(el.lat, el.lng)})
      mapRef.current.centerMap(L.latLngBounds(coords));
    }

  }));

  const { t } = useTranslation();

  const mapRef = React.useRef();

  const [markersArray, setMarkersArray] = React.useState([]);

  React.useEffect(() => {
    if(narrations && narrations.length > 0){
      const items = [];
      
      let allLocations = [];
      for(const n of narrations){
        for(const loc of n.locations){
          allLocations = allLocations.concat({
            narration: {
              title: n.text,
              id: n.id
            },
            location: loc
          });
        }
      }
      allLocations.sort(sortLocationsByCoordinates);

      const locs = allLocations.reduce((previousValue, currentValue) => { 
        const index = previousValue.findIndex(el => { return (el.location.lat === currentValue.location.lat) && (el.location.lng === currentValue.location.lng ) && (el.location.entityId !== currentValue.location.entityId) });
        if(index === -1){
          return previousValue.concat({
            entity: currentValue.location.entity,
            narrations: [currentValue.narration],
            location: currentValue.location
          });
        }
        else{
          previousValue[index].narrations.push(currentValue.narration)
          return previousValue;
        }
        
      }, []);

      for(const l of locs){        

        const popup = document.createElement("div");
        popup.style = "font-family: 'Gotham'";
        const narrationLabel = L.DomUtil.create("p", "popup-narration-label", popup);
        narrationLabel.style = "margin: 0; color: var(--text-light); font-size: x-small; font-weight: bold; text-transform: uppercase;";
        narrationLabel.textContent = l.entity;
        const narrationsContainer = L.DomUtil.create("div", "popup-narrations-content", popup);
        for(const n of l.narrations){
          const narr = L.DomUtil.create("p", "popup-narration-text", narrationsContainer);
          narr.style = "margin: .5rem 0 0 0; color: var(--text); font-size: small; font-weight: bold; cursor: pointer;";
          narr.textContent = n.title;
          narr.onclick = () => onSelectNarration(n);
        }
        
        items.push({
            popup: popup,
            coordinates: {
              lat: l.location.lat,
              lng: l.location.lng
            }
        })
      }
      setMarkersArray(items);
    }
    else{
      setMarkersArray([]);
    }
  }, [narrations, onSelectNarration, t]);
  
  return (
    <div className={classes.Wrapper}>
      <LeafletMap
        ref={mapRef}
        markers={markersArray}
        zIndex={0} 
      />
    </div>
  );

});

export default Map;