import React, { forwardRef, useImperativeHandle } from "react";
import { v4 } from "uuid";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import classes from './LeafletMap.module.css';

import MARKER from '../../assets/images/markerIcon.svg';
import INACTIVE_MARKER from '../../assets/images/markerIconInactive.svg';

const id = v4();

const LeafletMap = forwardRef((props, ref) => {

  const {
    markers,
    zIndex,
    onMapClick,
    disablePopup,
    allowClickOnMapEvent,
    disableInteractions,
    disableFitBounds,
    allowPanTo
  } = props;

  useImperativeHandle(ref, () => ({

    centerMap(latLngBounds) {
      map.setView(latLngBounds.getCenter());
    }

  }));

  const [map, setMap] = React.useState();
  const [firstUpdate, setFirstUpdate] = React.useState(true);
  
  React.useEffect(() => {

    const tileLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
	    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
	    subdomains: 'abcd',
	    maxZoom: 20
    });

    /*const tileLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    });*/

    const map = L.map(`leaflet-map-${id}`, { zoomControl: false });

    tileLayer.addTo(map);

    if(!disableInteractions){
      map.addControl(L.control.zoom({ position: 'bottomright' }));
    }
    else{
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();  
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) {
        map.tap.disable();
      }
    }

    map.setView([45.0578564352, 7.65664237342], 13);

    /*map.on('zoomend', function() {
      var currentZoom = map.getZoom();
      console.log("AAAAAAAAAAAAAAAAAAA", map);
    });*/

    setMap(map);

  }, [disableInteractions]);

  React.useEffect(() => {

    if(map && allowClickOnMapEvent){
      map.off("click");
      map.on('click', function(ev){
        var latlng = map.mouseEventToLatLng(ev.originalEvent);
        onMapClick(latlng);
      });
    }


  }, [map, allowClickOnMapEvent, onMapClick]);
  
  React.useEffect(() => {

    if(map){
      map.eachLayer((layer) => {
        if(!(layer instanceof L.TileLayer)){
          setFirstUpdate(false);
          map.removeLayer(layer);
        }
      })
    }

    if(map && markers && markers.length > 0){
      const icon = L.icon({
        iconUrl: INACTIVE_MARKER,
        iconSize: [20, 20], // size of the icon
      });
      const activeIcon = L.icon({
        iconUrl: MARKER,
        iconSize: [25, 25], // size of the icon
      });

      for(const m of markers){
        const marker = new L.marker([m.coordinates.lat, m.coordinates.lng], {icon: icon});
        if(!disablePopup && m.popup){
          marker.bindPopup(m.popup, {
            closeButton: false,
            maxWidth: 200
          });
          marker.on('mouseover', function (e) {
            marker.setIcon(activeIcon);
          });
          marker.on('mouseout', function (e) {
            //this.closePopup();
            marker.setIcon(icon);
          });
          marker.on('click', function (e) {
            marker.setIcon(activeIcon);
            this.openPopup();
          });
        }
        marker.addTo(map);

      }

      if(!disableFitBounds){
        map.fitBounds(markers.map(el => el.coordinates), { padding: [10, 10] });
      }

      if(allowPanTo || firstUpdate){
        map.panTo(markers[0].coordinates);
        if(firstUpdate){
          setFirstUpdate(false);
        }
      }
    }

  }, [markers, map, disablePopup, disableFitBounds, allowPanTo, firstUpdate]);
  
  return (
    <div id={`leaflet-map-${id}`} className={classes.LeafletMap} style={{ zIndex: zIndex }}>
    </div>
  );

});

export default LeafletMap;