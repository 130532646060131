import React from "react";
import { ToastContext } from "../../contexts/ToastContext";

import classes from "./Toast.module.css";

const ERROR_COLORS = {
  SUCCESS: "green",
  PARTIAL_SUCCESS: "gold",
  ERROR: "red",
}

const Toast = (props) => {
  
  const { 
    id,
    duration,
    message,
    status
  } = props;

  const ref = React.useRef();

  const { clearToastData } = React.useContext(ToastContext);

  const [disappear, setDisappear] = React.useState(false);

  const getColorByStatus = React.useCallback((status) => {
    let color;
    
    switch(status){
      case "success":
        color = ERROR_COLORS.SUCCESS;
        break;
      case "partialSuccess":
        color = ERROR_COLORS.PARTIAL_SUCCESS;
        break;
      case "error":
        color = ERROR_COLORS.ERROR;
        break;
      default:
        color = "inherit";
    }
    
    return color;

  }, []);

  React.useEffect(() => {
    if(disappear){
      ref.current.classList.add(classes.ToastNonVisible);
    }
  }, [disappear]);

  return (
    <div 
      ref={ref}
      className={[classes.Toast, classes.ToastVisible].join(" ")}
      onAnimationEnd={(e) => {
        if(e.animationName.includes("fade-in")){
          e.target.classList.remove(classes.ToastVisible);
          setTimeout(() => {
            setDisappear(true);
          }, duration*1000);
        }
        if(e.animationName.includes("fade-out")){
          e.target.classList.remove(classes.ToastNonVisible);
          clearToastData(id);
        }
      }}
    >
      <p 
        className={classes.ToastText}
        style={status ? { color: getColorByStatus(status) } : {}}
      >{message}</p>
    </div>
  );

};

export default Toast;