import React, { useState } from "react";
import AddKnowledge from "../AddKnowledge/AddKnowledge";
import AddNarration from "../AddNarration/AddNarration";
import AddRelations from "../AddRelations/AddRelations";
import TopBar from "../TopBar/TopBar";

import NARRATIONS from '../../assets/images/narrations.svg';
import NARRATIONS_ACTIVE from '../../assets/images/narrationsActive.svg';
import KNOWLEDGE from '../../assets/images/knowledge.svg';
import KNOWLEDGE_ACTIVE from '../../assets/images/knowledgeActive.svg';
import RELATIONS from '../../assets/images/relations.svg';
import RELATIONS_ACTIVE from '../../assets/images/relationsActive.svg';

import classes from './AuthorComponent.module.css';
import { useTranslation } from "react-i18next";
import { paragraphs, entities, relations, FEEDBACK_STATUSES } from '../../utils/constants';
import AuthorSaveAndOrExitButtons from "../AuthorSaveAndOrExitButtons/AuthorSaveAndOrExitButtons";
import AddSources from "../AddSources/AddSources";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";


const AuthorComponent = (props) => {

  const { 
    isEdit,
    data,
    saveData,
    removeData,
    relationDropdownDisabled
  } = props;

  const { setToastData } = React.useContext(ToastContext);

  const topBarRef = React.useRef();
  const addNarrationRef = React.useRef();
  const addKnowledgeRef = React.useRef();
  const addRelationsRef = React.useRef();
  const addSourcesRef = React.useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const [id, setId] = React.useState();
  const [owner, setOwner] = React.useState();
  const [title, setTitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [insertedFragments, setInsertedFragments] = React.useState([]);
  const [insertedEntities, setInsertedEntities] = React.useState([]);
  const [insertedRelations, setInsertedRelations] = React.useState([]);
  const [insertedSources, setInsertedSources] = React.useState([]);
  
  const [previousData, setPreviousData] = React.useState();

  const [alreadyExistingEntities, setAlreadyExistingEntities] = React.useState([]);
  const [alreadyExistingSources, setAlreadyExistingSources] = React.useState([]);


  const [omekaSClasses, setOmekaSClasses] = React.useState([]);
  const [omekaSProperties, setOmekaSProperties] = React.useState([]);

  const setArticle = React.useCallback((article) => {
    if(article.id){
      setId(article.id);
    }
    setOwner(article.owner);
    setTitle(article.title);
    setAuthor(article.narrator);
    setInsertedFragments(article.fragments);
    setInsertedEntities(article.entities);
    setInsertedRelations(article.relations);
    setInsertedSources(article.sources);
  }, []);
  
  const createEntitiesArrayForDropdown = React.useCallback(() => {
    const entArray = Array.from(alreadyExistingEntities); 
    const items = [];

    for(const e of insertedEntities){
      if(e.entity){
        items.push({
          ...e,
          entityOfNarration: true
        });
      }
      else{
        const index = entArray.findIndex(el => { return el.uuid === e.uuid });
        if(index !== -1){
          const element = entArray.splice(index, 1);
          if(element.length === 1){
            items.push({
              ...element[0],
              entityOfNarration: true
            });
          }
        }
      }
    }

    return items.concat(entArray);

  }, [insertedEntities, alreadyExistingEntities])

  const onCascadeRemoveRelations = React.useCallback((deletedEntities) => {
    const items = Array.from(insertedRelations);
    const deletedEntitiesUuids = deletedEntities.map(e => { return e.uuid; });
    const filteredItems = items.filter((r) => { return !deletedEntitiesUuids.includes(r.from) && !deletedEntitiesUuids.includes(r.to) });
    setInsertedRelations(filteredItems);
  }, [insertedRelations]);

  const onCascadeRemoveEntities = React.useCallback((deletedFragments) => {
    const items = Array.from(insertedEntities);
    const deletedFragmentsUuids = deletedFragments.map(f => { return f.uuid; });
    const filteredItems = items.filter((e) => { return !deletedFragmentsUuids.includes(e.fragmentUuid); });
    const entitiesToDelete = items.filter((e) => { return deletedFragmentsUuids.includes(e.fragmentUuid); });
    onCascadeRemoveRelations(entitiesToDelete);
    setInsertedEntities(filteredItems);
  }, [insertedEntities, onCascadeRemoveRelations]);

  const onCascadeRemoveSourcesFromFragments = React.useCallback((deletedSources) => {
    const items = Array.from(insertedFragments);
    const deletedSourcesUuids = deletedSources.map(s => { return s.uuid; });
    for(let i=0; i<items.length; i++){
      for(let j=0; j< items[i].sourceReferences; j++){
        const newSourceUuidsArray = items[i].sourceReferences[j].sources.filter( el => !deletedSourcesUuids.includes(el) );
        items[i].sourceReferences[j].sources = [ ...newSourceUuidsArray ];
      }
    }
    setInsertedFragments(items);
  }, [insertedFragments]);
  
  const addFragment = React.useCallback((newFragment) => {
    const items = Array.from(insertedFragments);
    items.push(newFragment);
    setInsertedFragments(items);
  }, [insertedFragments]);

  const removeFragment = React.useCallback((index) => {
    const items = Array.from(insertedFragments);
    const deletedFragments = items.splice(index, 1);
    onCascadeRemoveEntities(deletedFragments);
    setInsertedFragments(items);
  }, [insertedFragments, onCascadeRemoveEntities]);

  const editFragment = React.useCallback((newFragment, index) => {
    const items = Array.from(insertedFragments);
    items[index] = newFragment;
    setInsertedFragments(items);
  }, [insertedFragments]);

  const addEntity = React.useCallback((newEntity) => {
    const items = Array.from(insertedEntities);
    items.push(newEntity);
    setInsertedEntities(items);
  }, [insertedEntities]);

  const removeEntity = React.useCallback((index) => {
    const items = Array.from(insertedEntities);
    const deletedEntities = items.splice(index, 1);
    onCascadeRemoveRelations(deletedEntities);
    setInsertedEntities(items);
  }, [insertedEntities, onCascadeRemoveRelations]);

  const addRelation = React.useCallback((newRelation) => {
    const items = Array.from(insertedRelations);
    items.push(newRelation);
    setInsertedRelations(items);
  }, [insertedRelations]);

  const removeRelation = React.useCallback((index) => {
    const items = Array.from(insertedRelations);
    items.splice(index, 1);
    setInsertedRelations(items);
  }, [insertedRelations]);

  const editRelation = React.useCallback((newRelation, index) => {
    const items = Array.from(insertedRelations);
    items[index] = newRelation;
    setInsertedRelations(items);
  }, [insertedRelations]);

  const addSource = React.useCallback((newSource) => {
    const items = Array.from(insertedSources);
    items.push(newSource);
    setInsertedSources(items);
  }, [insertedSources]);

  const editSource = React.useCallback((newSource, index) => {
    const items = Array.from(insertedSources);
    items[index] = { ...newSource };
    setInsertedSources(items);
  }, [insertedSources]);

  const removeSource = React.useCallback((index) => {
    const items = Array.from(insertedSources);
    const deletedSources = items.splice(index, 1);
    onCascadeRemoveSourcesFromFragments(deletedSources);
    setInsertedSources(items);
  }, [insertedSources, onCascadeRemoveSourcesFromFragments]);

  const scrollToSection = React.useCallback((section) => {
    if(section === 0){
      addNarrationRef.current.containerRef.current.scrollIntoView({behavior: "smooth"});
    }
    else if(section === 1){
      addKnowledgeRef.current.scrollIntoView({behavior: "smooth"});
    }
    else{
      addSourcesRef.current.containerRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [addNarrationRef, addKnowledgeRef, addSourcesRef])

  const handleNavigation = React.useCallback((e) => {
    const window = e.currentTarget;
    const topBarHeight = topBarRef.current.clientHeight;
    const narrationSectionHeight = addNarrationRef.current.containerRef.current.clientHeight;
    const knowledgeSectionHeight = addKnowledgeRef.current.clientHeight;
    const relationsSectionHeight = addRelationsRef.current.clientHeight;

    if(window.scrollY <= (topBarHeight + narrationSectionHeight)){
      setActiveSection(0);
    }
    else if(window.scrollY > narrationSectionHeight && window.scrollY <= (topBarHeight + narrationSectionHeight + knowledgeSectionHeight + relationsSectionHeight)){
      setActiveSection(1);
    }
    else{
      setActiveSection(2);
    }
  }, [topBarRef, addNarrationRef, addKnowledgeRef]);

  const deleteData = React.useCallback(async () => {
    
    setLoading(true);
    
    const feedback = await removeData(previousData);
    let returnValue;

    if(!feedback.includes("ERROR")){
      setToastData(t("general.feedback.success", { context: "delete" }), FEEDBACK_STATUSES.SUCCESS);
      setTitle("");
      setAuthor("");
      if(addNarrationRef && addNarrationRef.current && addNarrationRef.current.resetInputFields()){
        addNarrationRef.current.resetInputFields();
      }
      setInsertedFragments([]);
      setInsertedEntities([]);
      setInsertedRelations([]);
      setInsertedSources([]);
      returnValue = 1;
    }
    else if(feedback.includes("ERROR") && feedback.includes("OK")){
      setToastData(t("general.feedback.partialSuccess", { context: "delete" }), FEEDBACK_STATUSES.PARTIAL_SUCCESS);
      setTitle("");
      setAuthor("");
      if(addNarrationRef && addNarrationRef.current && addNarrationRef.current.resetInputFields()){
        addNarrationRef.current.resetInputFields();
      }
      setInsertedFragments([]);
      setInsertedEntities([]);
      setInsertedRelations([]);
      setInsertedSources([]);
      returnValue = 0;
    }
    else{
      setToastData(t("general.feedback.error", { context: "delete" }), FEEDBACK_STATUSES.ERROR);
      returnValue = -1;
    }

    setLoading(false);

    return returnValue;
  
  }, [t, previousData, removeData, setToastData]);
  
  const loadData = React.useCallback(async () => {
    
    const checkedData = {
      narration: addNarrationRef.current.checkData(title),
      paragraphs: true,
      entities: true,
      relations: true,
      sources: addSourcesRef.current.checkData()
    }

    if(Object.values(checkedData).includes(false)){
      console.log("ERRORE, missing data");
      setToastData(t("general.feedback.dataNotComplete"), FEEDBACK_STATUSES.ERROR);
      return -1;
    }
    
    setLoading(true);
    
    const newArticleData = {
      id: id,
      title: title, 
      narrator: author, 
      insertedFragments: insertedFragments, 
      insertedEntities: insertedEntities, 
      insertedRelations: insertedRelations,
      insertedSources: insertedSources
    };

    const feedback = await saveData(omekaSClasses, omekaSProperties, previousData, newArticleData, alreadyExistingEntities, alreadyExistingSources);
    let returnValue;

    if(!feedback.includes("ERROR")){
      setToastData(t("general.feedback.success", { context: isEdit ? "edit" : "add" }), FEEDBACK_STATUSES.SUCCESS);
      setTitle("");
      setAuthor("");
      if(addNarrationRef && addNarrationRef.current && addNarrationRef.current.resetInputFields()){
        addNarrationRef.current.resetInputFields();
      }
      setInsertedFragments([]);
      setInsertedEntities([]);
      setInsertedRelations([]);
      setInsertedSources([]);
      returnValue = 1;
    }
    else if(feedback.includes("ERROR") && feedback.includes("OK")){
      setToastData(t("general.feedback.partialSuccess", { context: isEdit ? "edit" : "add" }), FEEDBACK_STATUSES.PARTIAL_SUCCESS);
      setTitle("");
      setAuthor("");
      if(addNarrationRef && addNarrationRef.current && addNarrationRef.current.resetInputFields()){
        addNarrationRef.current.resetInputFields();
      }
      setInsertedFragments([]);
      setInsertedEntities([]);
      setInsertedRelations([]);
      setInsertedSources([]);
      returnValue = 0;
    }
    else{
      setToastData(t("general.feedback.error", { context: isEdit ? "edit" : "add" }), FEEDBACK_STATUSES.ERROR);
      returnValue = -1;
    }

    setLoading(false);

    return returnValue;
  
  }, [t, id, previousData, omekaSClasses, omekaSProperties, title, author, insertedFragments, insertedEntities, insertedRelations, insertedSources, saveData, alreadyExistingEntities, alreadyExistingSources, isEdit, setToastData]);

  const manageData = React.useCallback(() => {
    if(data){
      setOmekaSClasses(data.classes);
      setOmekaSProperties(data.properties);
      if(data.alreadyExistingEntities){
        setAlreadyExistingEntities(data.alreadyExistingEntities);
      }
      if(data.alreadyExistingSources){
        setAlreadyExistingSources(data.alreadyExistingSources);
      }
      if(data.article){
        setArticle({ ...data.article });
        setPreviousData({ ...data.article });
      }
    }
    
  }, [data, setArticle]);

  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const onDelete = React.useCallback(async () => {
    const result = await deleteData();
    if(result !== -1){
      redirectTo("/visualization/narrations");
    }
  }, [deleteData, redirectTo]);
  
  const onSaveAndQuit = React.useCallback(async () => {
    const result = await loadData();
    if(result !== -1){
      redirectTo(isEdit ? "/visualization/narrations/"+id : "/visualization/narrations");
    }
  }, [loadData, isEdit, id, redirectTo]);

  const onSave = React.useCallback(async () => {
    const result = await loadData();
    if(result !== -1){
      setTimeout(() => {
        window.location.reload();
      }, 1*1000);
    }
  }, [loadData]);

  const onExit = React.useCallback(async () => {
    redirectTo(isEdit ? "/visualization/narrations/"+id : "/visualization/narrations")
  }, [isEdit, id, redirectTo]);
  
  React.useEffect(() => {
    if(data){
      manageData(data);
      setLoading(false);
    }

  }, [data, manageData]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };

  }, [handleNavigation]);

  React.useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className={classes.Wrapper}>
      <TopBar ref={topBarRef} isAuthor={true} isEdit={isEdit} onDelete={() => onDelete()} onSaveAndQuit={() => onSaveAndQuit()} onSave={() => onSave()} onExit={() => onExit()} />
      <div className={classes.MainContainer}>
        <div className={classes.AddContainer}>
          <AddNarration 
            ref={addNarrationRef}
            fragments={Object.values(paragraphs).map(f => { return f.label })}
            title={title}
            author={author && author.text ? author.text : ""}
            omekaSClasses={data.classes}
            omekaSProperties={data.properties}
            existingSources={alreadyExistingSources}
            insertedFragments={insertedFragments}
            insertedSources={insertedSources}
            onInsertTitle={(title) => setTitle(title)}
            onInsertAuthor={(text) => setAuthor({ id: author.id, text: text })}
            onInsertFragment={(newFragment) => addFragment(newFragment)}
            onRemoveFragment={(index) => removeFragment(index)}
            onEditFragment={(newFragment, index) => editFragment(newFragment, index)}
            onMoveFragment={(newInsertedFragments) => setInsertedFragments(newInsertedFragments)}
          />
          <AddKnowledge 
            ref={addKnowledgeRef}
            fragments={insertedFragments}
            omekaSClasses={data.classes}
            omekaSProperties={data.properties}
            entities={Object.values(entities).filter(e => { return e.author })/*.map(e => { return e.label })*/}
            existingEntities={alreadyExistingEntities}
            insertedEntities={insertedEntities}
            onInsertEntity={(newEntity) => addEntity(newEntity)}
            onRemoveEntity={(index) => removeEntity(index)}
          />
          <AddRelations 
            ref={addRelationsRef}
            omekaSClasses={omekaSClasses}
            omekaSProperties={omekaSProperties}
            entities={createEntitiesArrayForDropdown()}
            relations={Object.values(relations).filter(e => { return e.author })}
            insertedRelations={insertedRelations}
            onInsertRelation={(newRelation) => addRelation(newRelation)}
            onRemoveRelation={(index) => removeRelation(index)}
            onEditRelation={(newRelation, index) => editRelation(newRelation, index)}
            relationDropdownDisabled={relationDropdownDisabled}
          />
          <AddSources 
            ref={addSourcesRef}
            existingSources={alreadyExistingSources}
            insertedSources={insertedSources}
            onInsertSource={(newSource) => addSource(newSource)}
            onRemoveSource={(index) => removeSource(index)}
            onEditSource={(newSource, index) => editSource(newSource, index)}
          />
          <div className={classes.SaveAndOrExitButtonsContainer}>
            <AuthorSaveAndOrExitButtons 
              isEdit={isEdit}
              onDelete={() => onDelete()}
              onSaveAndQuit={() => onSaveAndQuit()} 
              onExit={() => onExit()}
            />
          </div>
        </div>
        <div className={classes.SpansContainer}>
          <div className={classes.SpansBox}>
            <div className={classes.Span} onClick={() => scrollToSection(0)}>
              <img src={activeSection === 0 ? NARRATIONS_ACTIVE : NARRATIONS} alt="" className={classes.SpanIcon} />
              <p className={activeSection === 0 ? [classes.SpanText, classes.Active].join(" ") : [classes.SpanText].join(" ")}>{t('general.span.narration')}</p>
            </div>
            <div className={classes.Span} onClick={() => scrollToSection(1)}>
              <img src={activeSection === 1 ? KNOWLEDGE_ACTIVE : KNOWLEDGE} alt="" className={classes.SpanIcon} />
              <p className={activeSection === 1 ? [classes.SpanText, classes.Active].join(" ") : [classes.SpanText].join(" ")}>{t('general.span.knowledge')}</p>
            </div>
            <div className={classes.Span} onClick={() => scrollToSection(2)}>
              <img src={activeSection === 2 ? RELATIONS_ACTIVE : RELATIONS} alt="" className={classes.SpanIcon} />
              <p className={activeSection === 2 ? [classes.SpanText, classes.Active].join(" ") : [classes.SpanText].join(" ")}>{t('general.span.relations')}</p>
            </div>
          </div>
        </div>
      </div>
      {
        loading &&
        <div className={classes.LoadingContainer}>
          <Loader size="7.5rem" />
        </div>
      }
    </div>
  );
};

export default AuthorComponent;