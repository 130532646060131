import React from 'react';
import { v4 } from 'uuid';
import Toast from '../components/Toast/Toast';

export const ToastContext = React.createContext();

const DEFAULT_TOAST_DURATION = 5;

const ToastContextProvider = (props) => {
  
  const [toastList, setToastList] = React.useState([]);

  const setData = React.useCallback((message, status, duration=null) => {
    const items = Array.from(toastList);
    items.push({
      id: "toast-"+v4(),
      duration: duration ? duration : DEFAULT_TOAST_DURATION,
      message: message,
      status: status
    })
    setToastList(items);
  }, [toastList]);

  const clearData = React.useCallback((id) => {
    const items = Array.from(toastList);
    const findIndex = items.findIndex(e => { return e.id === id });
    if(findIndex !== -1){
      items.splice(findIndex, 1);
      setToastList(items);
    }
  }, [toastList]);

  return (
    <ToastContext.Provider value={{ setToastData: setData, clearToastData: clearData }}>
      {props.children}
      {
        toastList.map(t => {
          return (
            <Toast
              key={t.id}
              id={t.id}
              duration={t.duration}
              message={t.message}
              status={t.status}
            />
          )
        }) 
      }
    </ToastContext.Provider>
  );

};

export default ToastContextProvider 
