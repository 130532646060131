import React from "react";
import { useTranslation } from "react-i18next";

import NarrationBox from "../NarrationBox/NarrationBox";

import classes from './NarrationNetwork.module.css';

const Marker = (props) => {
  return (
    <div className={props.className}>
      <svg viewBox="0 0 10 10">
        <path d="M0,0 L0,10 L10,5" fill="grey"></path>
      </svg>
    </div>
  )
}
const MultipleMarkerArrowStart = () => {
  return (
    <div style={{ display: 'flex', height: '2rem' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="100%" strokeWidth={2} stroke="grey" />
      </svg>
    </div>
  )
}
const MultipleMarkerArrowMiddle = () => {
  return (
    <div style={{ display: 'flex' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="100%" stroke="grey" strokeWidth={2} />
        <line x1="0" y1="50%" x2="100%" y2="50%" stroke="grey" strokeWidth={1} />
      </svg>
    </div>
  )
}
const MultipleMarkerArrowEnd = () => {
  return (
    <div style={{ display: 'flex' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="50%" stroke="grey" strokeWidth={2} />
        <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="grey" strokeWidth={1} />
      </svg>
    </div>
  )
}

const NarrationNetwork = (props) => {
  
  const {
    selectedNarration,
    narrationAuthor,
    fragmentsNodes
  } = props;

  const { t } = useTranslation();
  const [hoveredFragment, setHoveredFragment] = React.useState();

  React.useEffect(() => {
    if(fragmentsNodes && fragmentsNodes.length > 0){
      
      const items = document.getElementsByClassName(classes.FragmentContainer);
      let maxHeight = 0;
      for(const el of items){
        if(el.clientHeight > maxHeight){
          maxHeight = el.clientHeight;
        }
      };
      for(const el of items){
        if(el.clientHeight < maxHeight){
          const marginTop = parseFloat((maxHeight - el.clientHeight) / 2);
          el.style.marginTop = marginTop + "px";
        }
        const i = el.getElementsByClassName(classes.FragmentText);
        if(i.length === 1){
          if(i[0].scrollHeight > i[0].clientHeight){
            i[0].classList.add(classes.OverflowFragmentText);
          }
        }

      };
    }
  }, [fragmentsNodes]);

  return (
    <div className={classes.Container}>
      <div className={classes.BoxContainer}>
        <div className={classes.LeftColumn}>
          <NarrationBox 
            narration={selectedNarration} 
            fragmentsNodes={fragmentsNodes}
            onFragmentHover={(fragment) => setHoveredFragment(fragment)}
          />
        </div>
        <div className={classes.RightColumn}>
          <div className={classes.CustomNetworkWrapper}>
            <div className={classes.NarrationIntestation}>
              { narrationAuthor &&
                <div className={classes.IntestationFragment}>
                  <p className={classes.IntestationFragmentLabel}>{t('general.narrator')}</p>
                  <p className={classes.IntestationFragmentText}>{narrationAuthor}</p>
                </div>
              }
            </div>
            <div className={classes.NetworkContainer}>
              {
                fragmentsNodes.map((frag, index) => {
                  return (
                    <div 
                      className={hoveredFragment === frag.id ? [classes.GroupContainer, classes.HoveredFragment].join(" ") : [classes.GroupContainer].join(" ")} 
                      key={"custom-narration-fragment-container-"+frag.id}
                    >
                      <div className={classes.FragmentContainer}>
                        <div className={classes.Fragment}>
                          <p className={classes.FragmentLabel}>{frag.data.label}</p>
                          <p className={classes.FragmentText}>{frag.data.txt}</p>
                        </div>
                        { index < fragmentsNodes.length - 1 &&
                          <div className={classes.ArrowContainer}>
                            <div className={classes.ArrowLine}></div>
                            <Marker className={classes.ArrowMarker} />
                          </div>
                        }
                      </div>
                      <div className={classes.SourcesContainer}>
                        {
                          frag.data.sourceRefs.length > 0 &&
                          <div className={classes.Source}>
                            <div className={classes.SourceArrow}>
                              <MultipleMarkerArrowStart />
                            </div>
                          </div>
                        }
                        {
                          frag.data.sourceRefs.map((el, ind) => {
                            return (
                              <div key={"narration-fragment-"+frag.id+"-source-"+ind} className={classes.Source}>
                                <div className={classes.SourceArrow}>
                                  {
                                    ind < frag.data.sourceRefs.length - 1 ?
                                      <MultipleMarkerArrowMiddle /> :
                                      <MultipleMarkerArrowEnd /> 
                                  }
                                  <Marker className={classes.SourceArrowMarker}/>
                                </div>
                                <div className={classes.SourceBox}>
                                  <p className={classes.SourceBoxLabel}>{t("general.source")}</p>
                                  <p className={classes.SourceBoxText}>{el.txt}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NarrationNetwork;