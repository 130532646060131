import React from "react";

import Map from '../../../components/Map/Map';
import BottomNavigation from "../../../components/BottomNavigation/BottomNavigation";

import TopBar from "../../../components/TopBar/TopBar";

import { useTranslation } from "react-i18next";

import classes from "./Narrations.module.css";
import NarrationsNetwork from "../../../components/NarrationsNetwork/NarrationsNetwork";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import SearchBox from "../../../components/SearchBox/SearchBox";
import { doubleClickDelay, VIZ_TYPES, SEARCH_TYPES, SEARCH_TYPE_PERMISSIONS } from "../../../utils/constants";
import { getObjectByProperty } from "../../../utils/methods";


const buttons = [
  { label:"Map", code: VIZ_TYPES.MAP }, 
  //{ label:"Timeline", code: vizTypes.TIMELINE }, 
  { label:"Network", code: VIZ_TYPES.NETWORK }
]

const delay = doubleClickDelay;

const Narrations = (props) => {

  const {
    narrations,
    knowledge
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const mapRef = React.useRef();

  const [vizType, setVizType] = React.useState(VIZ_TYPES.NETWORK);
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [searchTypes, setSearchTypes] = React.useState([SEARCH_TYPES.KNOWLEDGE]);
  const [narrationsFounded, setNarrationsFounded] = React.useState([]);
  const [narrationHovered, setNarrationHovered] = React.useState();
  const [timer, setTimer] = React.useState(null);
  const [clickedNarrObj, setClickedNarrObj] = React.useState(null);
  const [filterNarrations, setFilterNarrations] = React.useState(false);

  const selectNarration = React.useCallback((narration) => {
    navigate("./"+narration.id);
  }, [navigate]);

  const onSetSearchTypes = React.useCallback((types, isAdd) => {
    if(isAdd){
      const newTypesArray = searchTypes.concat(types);
      setSearchTypes(newTypesArray);
    }
    else{
      const items = [...searchTypes];
      for(const type of types){
        const index = items.indexOf(type);
        if(index !== -1){
          items.splice(index,1);
        }
      }
      setSearchTypes(items);
    }
  }, [searchTypes]);

  const textToSearch = React.useCallback((txt) => {
    setSearchText(txt);
  }, []);

  const highlight = React.useCallback((text, textContent) => {
    const regex = new RegExp('('+text+')', 'ig');
    const wordOccurrences = (textContent.match(regex) || []).length;
    let tempStr = textContent;
    const extraCharacterToCount = 15;
    const array = [];
    for(let i=0; i<wordOccurrences; i++){
      const index = tempStr.toLowerCase().indexOf(text.toLowerCase());
      const str = tempStr.substring(index - extraCharacterToCount, index+text.length+ extraCharacterToCount + 1)
      let dotLeft = true;
      let dotRight = true;
      if(i === 0){
        if(index <= extraCharacterToCount){
          dotLeft = false;
        }
      }
      
      if(i === wordOccurrences -1) { 
        if(index+text.length+extraCharacterToCount >= tempStr.length){
          dotRight = false;
        }
      }

      array.push({
        str: str,
        dotLeft: dotLeft,
        dotRight: dotRight
      });
      tempStr = tempStr.replace(str, "");
    }
    const res = array.map(el => {
      const htmlString = el.str.replace(regex, '<span style="background-color: yellow">$1</span>');
      return `${el.dotLeft ? "..." : ""}${htmlString}${el.dotRight ? "..." : ""}` 
    })
    return <p dangerouslySetInnerHTML={{__html: res.join("")}} style={{ margin: 0, textTransform: 'none' }}></p>
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearchText();
    setNarrationsFounded([]);
  }, []);

  const isAllowedSearch = React.useCallback((permission) => {
    for(const t of searchTypes){
      if(permission.includes(t)){
        return true;
      }
    }
    return false;
  }, [searchTypes]);

  const centerViz = React.useCallback((narr) => {
    switch(vizType){
      case VIZ_TYPES.MAP:
        if(narr.locations && narr.locations.length > 0){
          mapRef.current.centerMap(narr.locations);
        }
        break;
      case VIZ_TYPES.NETWORK:
        break;
      default:
        break;
    }
  }, [vizType]);

  const onSingleDoubleClickHandler = (narrObj) => {
    if(timer){
      clearTimeout(timer);
      setTimer(null);
      console.log("Double Click");
      if(clickedNarrObj === narrObj){
        selectNarration(narrObj);
      }
      else{
        const narr = getObjectByProperty(narrObj.id, "id", narrations);
        if(narr){
          centerViz(narr);
        }
      }
      setClickedNarrObj(null);
    }
    else{
      setClickedNarrObj(narrObj);
      setTimer(
        setTimeout(() => {
          console.log("Single Click");
          const narr = getObjectByProperty(narrObj.id, "id", narrations);
          if(narr){
            centerViz(narr);
          }
          setTimer(null);
          setClickedNarrObj(null);
        }, delay * 1000)
      );
    }
  };

  React.useEffect(() => {
    if(narrations && knowledge && narrations.length > 0){
      setTimeout(() => {
        setLoading(false);
      }, 1*1000);
    }
  }, [narrations, knowledge]);

  React.useEffect(() => {
    if(searchText && searchText.length >= 3){
      const lowerCaseText = searchText.toLowerCase();
      const item = [];
      if(narrations){
        for(const n of narrations){
          const narrObj = {
            id: n.id,
            title: n.text,
            connectedObjects: []
          };
          if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.NARRATION) && n.text && n.text.toLowerCase().includes(lowerCaseText)){
            narrObj.connectedObjects.push({
              text: highlight(searchText, n.text),
              type: t("general.title")
            });
          }
          if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.NARRATOR) && n.narrator && n.narrator.toLowerCase().includes(lowerCaseText)){
            narrObj.connectedObjects.push({
              text: highlight(searchText, n.narrator),
              type: t("general.narrator")
            });
          }
          if(n.fragments){
            for(const f of n.fragments){
              if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.FRAGMENT) && f.text && f.text.toLowerCase().includes(lowerCaseText)){
                narrObj.connectedObjects.push({
                  text: highlight(searchText, f.text),
                  type: t("general.fragments."+f.label)
                });
              }
              if(f.knowledgeEncoding){
                for(const knId of f.knowledgeEncoding){
                  if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.KNOWLEDGE)){
                    const knowledgeElement = knowledge.filter(el => { return el.id === knId })
                    if(knowledgeElement && knowledgeElement.length === 1){
                      const k = knowledgeElement[0];
                      if(k.text && k.text.toLowerCase().includes(lowerCaseText)){
                        narrObj.connectedObjects.push({
                          text: highlight(searchText, k.text),
                          type: t("general.entities."+k.label)
                        });
                      }
                    }
                  }
                }
              }
              if(f.sourceReferences){
                for(const sr of f.sourceReferences){
                  if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.SOURCE_REFERENCE) && sr.txt && sr.txt.toLowerCase().includes(lowerCaseText)){
                    narrObj.connectedObjects.push({
                      text: highlight(searchText, sr.txt),
                      type: t("general.sourceReference")
                    });
                  }
                  if(sr.sources){
                    for(const s of sr.sources){
                      if(isAllowedSearch(SEARCH_TYPE_PERMISSIONS.SOURCE) && s.title && s.title.toLowerCase().includes(lowerCaseText)){
                        narrObj.connectedObjects.push({
                          text: highlight(searchText, s.title),
                          type: t("general.source")
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          if(narrObj.connectedObjects.length > 0){
            item.push(narrObj);
          }  
        }
      }
      setNarrationsFounded([...item]);
    }
    else{
      setNarrationsFounded([]);
    }
  }, [searchText, searchTypes, narrations, knowledge, isAllowedSearch, highlight, t]);

  return (
    <div className={classes.Wrapper}>
      { 
        vizType === VIZ_TYPES.MAP && 
        <Map 
          ref={mapRef}
          narrations={narrations}
          onSelectNarration={selectNarration}
          hoveredNarration={narrationHovered} //da gestire su mappa (ancora non gestito)
        /> 
      }
      { 
        vizType === VIZ_TYPES.NETWORK &&
        <NarrationsNetwork 
          narrations={narrations}
          knowledge={knowledge}
          onSelectNarration={selectNarration}
          hoveredNarration={narrationHovered}
          narrationsFounded={narrationsFounded}
          filterNarrations={filterNarrations}
        />
      }
      <TopBar 
        style={{ position: 'absolute', transform: 'translate(-50%, 0)', top: 0, left: '50%', zIndex: '1' }}
        currentSearchTypes={searchTypes}
        onSetSearchTypes={(types, isAdd) => onSetSearchTypes(types, isAdd)}
        onSearch={(text) => textToSearch(text)}
        onClearSearch={() => clearSearch()}
        onFilterNarrations={(value) => setFilterNarrations(value)}
        narrationsFiltered={filterNarrations}
        currentVizType={vizType}
      />
      {
        narrationsFounded.length > 0 &&
        <div className={classes.SearchContainer}>
          {
            narrationsFounded.map((narrObj, index) => {
              return (
                <SearchBox 
                  key={"search-box-key-"+index}
                  style={{ marginBottom: '1rem' }}
                  narrId={narrObj.id}
                  narrTitle={narrObj.title}
                  connectedObjects={narrObj.connectedObjects}
                  onHoverStart={() => setNarrationHovered(narrObj.id)}
                  onHoverEnd={() => setNarrationHovered()}
                  onClick={() => onSingleDoubleClickHandler(narrObj)}
                />
              )
            })
          }
        </div>
      }
      <BottomNavigation
        style={{ position: 'absolute', transform: 'translate(-50%, 0)', bottom: '3rem', left: '50%' }}
        buttons={buttons}
        activeCode={vizType}
        change={newVt => setVizType(newVt)}
      />
      {
        loading &&
        <div className={classes.LoadingContainer}>
          <Loader size="7.5rem" />
        </div>
      }
    </div>
  );
};

export default Narrations;