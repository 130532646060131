import React from "react";

import classes from "./Loader.module.css";


const Loader = (props) => {

  const {
    size,
    color
  } = props;

  return (
    <div className={classes.LoaderBox}>
      <div className={classes.Loader} style={{ fontSize: size, color: color ? color : "var(--primary)" }}></div>
    </div>
  );

};

export default Loader;