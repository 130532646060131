import React from "react";
import { useTranslation } from "react-i18next";
import { commonProperties, supportedFileExtensions } from "../../utils/constants";

import classes from './NarrationSources.module.css';

import DOCUMENT_ICON from "../../assets/images/file.svg";
import DOCUMENT_AUDIO_ICON from "../../assets/images/file.svg";
import DOCUMENT_VIDEO_ICON from "../../assets/images/file.svg";

const NarrationSources = (props) => {
  
  const {
    narration
  } = props;

  const { t } = useTranslation();

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedSourceRef, setSelectedSourceRef] = React.useState();
  const [selectedSource, setSelectedSource] = React.useState();
  const [selectedMedia, setselectedMedia] = React.useState();

  const openPopup = React.useCallback((sourceReference, source, media) => {
    setselectedMedia(media);
    setSelectedSource(source);
    setSelectedSourceRef(sourceReference);
    setPopupOpen(true);
  }, []);

  const closePopup = React.useCallback(() => {
    setselectedMedia();
    setSelectedSource();
    setSelectedSourceRef();
    setPopupOpen(false);
  }, []);

  const openMedia = React.useCallback((sourceReference, source, media) => {
    const mediaType = media[commonProperties.MEDIA_TYPE];
    const originalUrl = media[commonProperties.ORIGINAL_URL];
    if(mediaType.includes(supportedFileExtensions.IMAGE.replace("data:", ""))){
      openPopup(sourceReference, source, media);
    }
    else{
      window.open(originalUrl, "_blank");
    }
  }, [openPopup]);

  const setImageBasedOnSourceFile = React.useCallback((media) => {
    
    const mediaType = media[commonProperties.MEDIA_TYPE];
    if(mediaType){
      if(mediaType.includes(supportedFileExtensions.IMAGE.replace("data:", ""))){
        const imageUrl = media[commonProperties.ORIGINAL_URL];
        return imageUrl ? imageUrl : DOCUMENT_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.AUDIO.replace("data:", ""))){
        return DOCUMENT_AUDIO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.VIDEO.replace("data:", ""))){
        return DOCUMENT_VIDEO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.TEXT.replace("data:", ""))){
        return DOCUMENT_ICON;
      }
      else{
        return DOCUMENT_ICON;
      }
    }
    else{
      return DOCUMENT_ICON;
    }
  }, []);

  React.useEffect(() => {
    console.log(selectedMedia, selectedSource, selectedSourceRef)
  }, [selectedMedia, selectedSource, selectedSourceRef])

  return (
    <div className={classes.Wrapper}>
      <div className={classes.ContentContainer}>
        { 
          narration.fragments.map(el => {
            if(el.sourceReferences.length > 0){
              return (
                <div 
                  key={"narration-"+narration.id+"-source-box-fragment-"+el.id} 
                  className={classes.FragmentContainer}
                >
                  <p className={classes.FragmentTitle}>{t('general.fragments.'+el.label)}</p>
                  <div className={classes.SourcesContainer}>
                    {
                      el.sourceReferences.map(s => {
                        if(s.sources) {
                          return s.sources.map((src, index) => {
                            return (
                              <div
                                key={"narration-"+narration.id+"-source-box-fragment-"+el.id+"-source-"+index} 
                                className={classes.SourceBox} 
                              >
                                <p className={classes.SourceBoxLabel}>{t("general.source")}</p>
                                <p className={classes.SourceBoxText}>{src.title}</p>
                                <div className={classes.SourceBoxMediaRow}>
                                  {
                                    src.media.map((m, i) => {
                                      return (
                                        <div
                                          key={"narration-"+narration.id+"-source-box-fragment-"+el.id+"-source-"+index+"-media-"+i} 
                                          className={classes.SourceBoxMediaContainer}
                                          onClick={() => openMedia(s, src, m)}
                                        >
                                        <img
                                          className={classes.SourceBoxMediaImage} 
                                          alt=""
                                          src={setImageBasedOnSourceFile(m)}
                                          title={m[commonProperties.SOURCE]}
                                        />
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                        else{
                          return null;
                        }
                      })
                    }
                  </div>
                </div>
              )
            }
            else{
              return (
                <div 
                  key={"narration-"+narration.id+"-source-box-fragment-"+el.id}
                  className={classes.FragmentContainer}
                >
                  <p className={classes.FragmentTitle}>{t('general.fragments.'+el.label)}</p>
                </div>
              );
            }
          })
        }
      </div>
      {
        popupOpen &&
        <div className={classes.PopupContainer}>
          <div className={classes.PopupBoxContainer}>
            <img className={classes.PopupImage} alt="" src={selectedMedia[commonProperties.ORIGINAL_URL]} />
            <div className={classes.PopupInfoColumn}>
              <div className={classes.ClosePopupContainer}>
                <p className={classes.ClosePopupIcon} onClick={() => closePopup()}>X</p>
              </div>
              <div className={classes.PopupInfoContainer}>
                <div className={classes.PopupInfoRow}>
                  <p className={classes.PopupInfoLabel}>Riferimento Fonte:</p> 
                  <p className={classes.PopupInfoText}>{selectedSourceRef.txt ? selectedSourceRef.txt : "-"}</p>
                </div>
                <div className={classes.PopupInfoRow}>
                  <p className={classes.PopupInfoLabel}>Fonte:</p> 
                  <p className={classes.PopupInfoText}>{selectedSource.title ? selectedSource.title : "-"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );

};

export default NarrationSources;