import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as translationsIT from "./translations/it.json";
import * as translationsEN from "./translations/en.json";

const i18NInstance = i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: { 
        ...translationsEN 
      }
    },
    it: {
      translation: {
        ...translationsIT
      }
    },
  },
  lng: "it", // if you're using a language detector, do not define the lng option
  fallbackLng: "it",

  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  }
  
});

  export default i18NInstance;
