import { t } from "i18next";
import React from "react";

import CHEVRON_DOWN from '../../assets/images/keyboard_arrow_down.svg';

import classes from './Dropdown.module.css';

const Dropdown = (props) => {
  
  const {
    id,
    placeholder,
    value,
    elements,
    outlined,
    onSelectValue,
    disabled
  } = props;

  const ref = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [filteredElements, setFilteredElements] = React.useState([]);
   
  const filterElements = React.useCallback((text) => {
    if(text){
      const result = [];
      for(const e of elements){
        if(e.label.toLowerCase().includes(text.toLowerCase())){
          result.push(e);
        }
      }
      setFilteredElements(result);
    }
    else{
      setFilteredElements(elements);
    }
  }, [elements]);
  
  React.useEffect(() => {
        
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [ref]);

  React.useEffect(() => {
    setFilteredElements(elements);
  }, [elements]);

  return (
    <div 
      id={id} 
      ref={ref} 
      className={classes.DropdownContainer} 
      style={disabled ? { cursor: 'not-allowed' } : {} }
      title={disabled ? ((value === -1 || (value !== -1 && elements.length <= value)) ? "" : (elements[value].value.title ? elements[value].value.title : elements[value].label)) : null}
    >
      <div style={disabled ? { pointerEvents: 'none' } : {} }>
        <div 
          className={open ? 
            [classes.ButtonContainer, classes.Open, outlined ? classes.Outlined : null, value === -1 ? classes.Placeholder : null].join(" ") : 
            [classes.ButtonContainer, classes.Closed, outlined ? classes.Outlined : null, value === -1 ? classes.Placeholder : null].join(" ")
          } 
          onClick={() => setOpen(!open)}
        >
          <p 
            className={classes.DropdownValue}
            title={(value === -1 || (value !== -1 && elements.length <= value)) ? "" : (elements[value].value.title ? elements[value].value.title : elements[value].label)}
          >{(value === -1 || (value !== -1 && elements.length <= value)) ? placeholder : elements[value].label}</p>
          <img src={CHEVRON_DOWN} alt="" className={[classes.DropdownIcon, open ? classes.DropdownIconOpen : classes.DropdownIconClosed].join(" ")} />
        </div>
      </div>
      {
        open && 
        <div className={classes.DropdownArea}>
          <div className={classes.FilterContainer}>
            <input 
              type="text" 
              className={classes.FilterInput}
              placeholder={t("general.dropdown.filterPlaceholder")}
              onChange={(event) => filterElements(event.target.value)}
            />
          </div>
          <p 
            className={classes.DropdownSelection}
            onClick={() => {
              onSelectValue(null);
              setOpen(false);
            }}
          >&nbsp;</p>
          {
            filteredElements && Array.isArray(filteredElements) && filteredElements.length > 0 &&
            filteredElements.map((el, index) => {
              return (
                <p 
                  key={"dropdown-element-"+id+"-"+index} 
                  className={classes.DropdownSelection}
                  title={el.value.title ? el.value.title : el.label}
                  onClick={() => {
                    onSelectValue(el.value);
                    setOpen(false);
                  }}
                >
                  {el.label}
                </p>
              )
            })
          }
          {
            (!elements || !Array.isArray(elements) || elements.length === 0) &&
            <p className={[classes.DropdownSelection, classes.NoSelection].join(" ")}>{t('general.dropdown.noValue')}</p>
          }
        </div>
      }
    </div>
  );

};

export default Dropdown;