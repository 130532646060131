import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import Author from '../../pages/Author/Author';
import Login from '../../pages/Login/Login';
import Visualization from "../../pages/Visualization/Visualization";

import { routes } from './RouterManager';
import UserContextProvider from '../../contexts/UserContext';
import AddUser from '../../pages/AddUser/AddUser';


const Router = () => {

  return (
    <UserContextProvider>
      <Routes>
        <Route path={routes.LOGIN.name} element={<Login />} />
        <Route path={routes.ADD_USER.name} element={<AddUser />} />
        <Route path={routes.VISUALIZATION.name+"/*"} element={<Visualization />} />
        <Route path={routes.AUTHOR.name+"/*"} element={<Author />} />
        <Route path="*" element={<Navigate to={routes.VISUALIZATION.name} />}/>
      </Routes>
    </UserContextProvider>
  );
}

export default Router;