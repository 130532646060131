import React from "react";

import classes from './Popup.module.css';

const Popup = (props) => {
  
  const {
    width
  } = props;

  return (
    <div className={classes.PopupBackground}>
      <div className={classes.PopupContainer} style={width ? { width: width } : {}}>
        {props.children}
      </div>
    </div>
  );

};

export default Popup;