import React from "react"
import { Canvas, Edge, MarkerArrow, Node } from 'reaflow';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { CustomNode } from "./CustomNode/CustomNode";

import classes from './NetworkGraph.module.css';
import { doubleClickDelay } from "../../../utils/constants";

const delay = doubleClickDelay;

const NetworkGraph = (props) => {

  const {
    hoveredNarration,
    nodes,
    edges,
    renderPopup,
    onNodeEnter,
    onNodeLeave,
    onNodeClick,
    onNodeDoubleClick,
    onEdgeEnter,
    onEdgeLeave,
    onEdgeClick,
    arrow,
    direction,
    panning,
    NodeComponent,
    nodeColor = "#FFFFFF",
    nodeHoverColor = "var(--primary-light)",
    edgeColor = "#A9AAAC",
    edgeHoverColor = "var(--primary)"
  } = props;
  
  const ref = React.useRef();

  const [wrapperReference, setWrapperReference] = React.useState(null);
  const [popup, setPopup] = React.useState({ visible: false, position: { x: 0, y: 0 }, content: null });
  const [hoveredNode, setHoveredNode] = React.useState();
  const [hoveredEdge, setHoveredEdge] = React.useState();
  const [timer, setTimer] = React.useState(null);

  const fitCanvas = React.useCallback(() => {
    if(wrapperReference && ref.current){
      //const scaleX = wrapperReference.instance.wrapperComponent.clientWidth / (ref.current.canvasWidth / ref.current.zoom);
      //const scaleY = wrapperReference.instance.wrapperComponent.clientHeight / (ref.current.canvasHeight / ref.current.zoom);
      //wrapperReference.centerView(Math.max(scaleX, scaleY));
      wrapperReference.centerView(0.5);
    }
  }, [wrapperReference]);

  const nodeEntered = React.useCallback((node) => {
    if(onNodeEnter){
      return onNodeEnter(node);
    }
  }, [onNodeEnter]);

  const nodeLeft = React.useCallback((node) => {
    if(onNodeLeave){
      return onNodeLeave(node);
    }
  }, [onNodeLeave]);
  
  const nodeClicked = React.useCallback((node) => {
    if(onNodeClick){
      return onNodeClick(node);
    }
  }, [onNodeClick]);

  const nodeDoubleClicked = React.useCallback((node) => {
    if(onNodeDoubleClick){
      return onNodeDoubleClick(node);
    }
  }, [onNodeDoubleClick]);

  const edgeEntered = React.useCallback((edge) => {
    if(onEdgeEnter){
      return onEdgeEnter(edge);
    }
  }, [onEdgeEnter]);

  const edgeLeft = React.useCallback((edge) => {
    if(onEdgeLeave){
      return onEdgeLeave(edge);
    }
  }, [onEdgeLeave]);
  
  const edgeClicked = React.useCallback((edge) => {
    if(onEdgeClick){
      return onEdgeClick(edge);
    }
  }, [onEdgeClick]);

  const popupRendered = React.useCallback((data) => {
    if(renderPopup){
      return renderPopup(data);
    }
    else {
      return null;
    }
  }, [renderPopup]);

  const onSingleDoubleClickHandler = (node) => {
    if(timer){
      clearTimeout(timer);
      setTimer(null);
      console.log("Double Click");
      nodeDoubleClicked(node);
    }
    else{
      setTimer(
        setTimeout(() => {
          console.log("Single Click");
          nodeClicked(node);
          setTimer(null);
        }, delay * 1000)
      );
    }
  };

  React.useEffect(() => {
    fitCanvas();
  }, [fitCanvas]);

  return (
    <div className={classes.Wrapper}>
      <TransformWrapper
        wheel={{ step: 0.025 }}
        onInit={(wrappeRef) => setWrapperReference(wrappeRef) }
        limitToBounds={true}
        minScale={0.01}
        maxScale={100}
        panning={panning}
      >
        <TransformComponent
          wrapperStyle={{ width: '100%', height: '100%', position: 'relative' }}
        >
          {popup.visible ? popupRendered(popup) : null}
          <Canvas
            maxWidth={50 * 1000}
            maxHeight={50 * 1000}
            ref={ref}
            zoomable={false}
            readonly={true}
            nodes={nodes}
            edges={edges}
            arrow={arrow !== undefined ? arrow : <MarkerArrow style={{ fill: edgeColor }} />}
            direction={direction ? direction : "DOWN" }
            onLayoutChange={(l) => { 
              if(l.width>0 && l.height>0) {
                ref.current.fitCanvas();
                fitCanvas();
              }
            }}
            edge={(edge) => {
              return (
                <Edge
                  className={hoveredEdge === edge.id ? [classes.Edge, classes.HoveredEdge].join(" ") : classes.Edge}
                  style={{ stroke: edgeColor  }}
                  onEnter={(event, edge) => {
                    //console.log(event, edge);
                    event.target.parentNode.childNodes[0].style.stroke = edgeHoverColor;
                    const newPopupData = {
                      visible: true,
                      position: {
                        x:  event.nativeEvent.offsetX,
                        y:  event.nativeEvent.offsetY
                      },
                      data: edge.data
                    }
                    setHoveredEdge(edge.id);
                    setPopup(newPopupData);
                    edgeEntered(edge);
                  }}
                  onLeave={(event, edge) => { 
                    //console.log(event, edge);
                    event.target.parentNode.childNodes[0].style.stroke = edgeColor;
                    const newPopupData = {
                      visible: false,
                      position: {
                        x: 0,
                        y: 0
                      },
                      data: null
                    }
                    setHoveredEdge(null);
                    setPopup(newPopupData);
                    edgeLeft(edge);
                  }}
                  onClick={(event, edge) => {
                    //console.log(event, edge);
                    edgeClicked(edge);
                  }}
                />
              )
            }}
            node= {(node) => {
              return (
                <Node
                  rx="1rem"
                  ry="1rem"
                  {...node}
                  className={classes.Node}
                  style={{ fill: nodeColor, filter: 'drop-shadow(0 5px 10px rgba(0, 0, 0, 0.14))' }}
                  onEnter={(event, node) => { 
                    //console.log(event, node);
                    event.target.style.fill = nodeHoverColor;
                    setHoveredNode(node.id);
                    nodeEntered(node);
                  }}
                  onLeave={(event, node) => { 
                    //console.log(event, node);
                    event.target.style.fill = nodeColor;
                    setHoveredNode(null);
                    nodeLeft(node);
                  }}
                  onClick={(event, node) => { 
                    //console.log(event, node) 
                    onSingleDoubleClickHandler(node);
                  }}
                  
                >
                  { event => (
                      <CustomNode 
                        //txt={event.node.data.txt} 
                        width={event.width} 
                        height={event.height} 
                        id={event.node.id} 
                        //label={event.node.data.label}
                        selected={event.node.selected}
                        hovered={hoveredNarration === event.node.id || hoveredNode === event.node.id}
                        data={event.node.data}
                        NodeComponent={NodeComponent}
                      />
                    ) 
                  }
                </Node>
              )
            }}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );

};

export default NetworkGraph;