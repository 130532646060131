export const routes = {
  LOGIN: {
    name: 'login',
  },
  ADD_USER: {
    name: 'add-user',
  },
  VISUALIZATION: {
    name: 'visualization',
    child: {
      NARRATIONS: {
        name: 'narrations',
        params: {
          ID: "id"
        }
      }
    }
  },
  AUTHOR: {
    name: 'author',
    params: {
      ID: "id"
    }
  }
}