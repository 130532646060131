import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import { ToastContext } from "../../contexts/ToastContext";
import { UserContext } from "../../contexts/UserContext";
import { FEEDBACK_STATUSES, roles } from "../../utils/constants";

import classes from "./AddUser.module.css";

import BACK from '../../assets/images/back.svg';

const axios = require('axios').default;


const AddUser = () => {

  const { userLogged, userData } = React.useContext(UserContext);
  const { setToastData } = React.useContext(ToastContext);

  const { t } = useTranslation();
  //const { state: routeState } = useLocation(); 
  const navigate = useNavigate();

  const [username, setUsername] = React.useState("");
  const [role, setRole] = React.useState();
  const [omekasId, setOmekasId] = React.useState();
  const [keyIdentity, setkeyIdentity] = React.useState("");
  const [keyCredential, setKeyCredential] = React.useState("");
  const [password, setPassword] = React.useState("");

  const setArrayForDropdown = React.useCallback(() => {
    return [
      { level: roles.ADMINISTRATOR, label: "administrator" }, 
      { level: roles.EDITOR, label: "editor" },
      { level: roles.GUEST, label: "guest" }
    ].map((r) => { 
      return {
        label: t('general.roles.'+r.label),
        value: r.level
      }
    })
  }, [t,]);

  const findIndexValue = React.useCallback((role) => {
    const items = setArrayForDropdown();
    if(role !== null && role !== undefined){
      for(const [index, r] of Object.entries(items)){
        if(role === r.value){
          return parseInt(index);
        }
      }
    }
    return -1;
  }, [setArrayForDropdown]);

  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const checkData = React.useCallback(() => {
    if(username && password && (!isNaN(parseInt(role)) && parseInt(role) >= 0) && (!isNaN(parseInt(omekasId)) && parseInt(omekasId) > 0) && keyIdentity && keyCredential){
      return true;
    }
    return false;
  }, [username, password, role, omekasId, keyIdentity, keyCredential])

  const onAddUser = React.useCallback(() => {
    const body = { 
      username: username, 
      password: password,
      role: role, 
      omekasId: omekasId, 
      keyIdentity: keyIdentity, 
      keyCredential: keyCredential
    };

    axios.post("/api/createUser", body).then(
      (res) => {
        console.log("User Created", res);
        setToastData(t("general.feedback.addUser", { context: FEEDBACK_STATUSES.SUCCESS }), FEEDBACK_STATUSES.SUCCESS, 2);
        setTimeout(() => {
          redirectTo("/visualization/narrations");
        }, 1* 1000);
        
      },
      (error) => {
        setToastData(t("general.feedback.addUser", { context: FEEDBACK_STATUSES.ERROR }), FEEDBACK_STATUSES.ERROR, 2);
        console.log("ERROR", error);
      }
    );
  }, [username, password, role, omekasId, keyIdentity, keyCredential, setToastData, redirectTo, t]);

  React.useEffect(() => {
    if(userData){
      if(!userLogged || userData.role > roles.ADMINISTRATOR){
        redirectTo("/visualization/narrations");
      }
    }
  }, [userData, userLogged, redirectTo]);
  
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Container}>
        <div className={classes.HeaderRow}>
          <p className={classes.Title}>HISTORYGRAPHIA</p>
          <p className={classes.SubTitle}>Aggiungi utente</p>
        </div>
        <div className={classes.Divider}/>
        <div className={classes.AddUserContainer}>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.username')}</p>
            <input 
              className={classes.Input} 
              type="text" 
              placeholder={t('general.addUser.usernamePlaceholder')}
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.role')}</p>
            <Dropdown 
              id="select-user-role-dropdown"
              placeholder={t("general.addUser.rolePlaceholder")}
              value={findIndexValue(role)}
              elements={setArrayForDropdown()}
              onSelectValue={(value) => setRole(value) }
            />
          </div>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.omekasId')}</p>
            <input 
              className={classes.Input} 
              type="number"
              min={0} 
              placeholder={t('general.addUser.omekasIdPlaceholder')}
              onChange={(event) => setOmekasId(parseInt(event.target.value))}
            />
          </div>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.keyIdentity')}</p>
            <input 
              className={classes.Input} 
              type="text" 
              placeholder={t('general.addUser.keyIdentityPlaceholder')}
              onChange={(event) => setkeyIdentity(event.target.value)}
            />
          </div>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.keyCredential')}</p>
            <input 
              className={classes.Input} 
              type="text" 
              placeholder={t('general.addUser.keyCredentialPlaceholder')}
              onChange={(event) => setKeyCredential(event.target.value)}
            />
          </div>
          <div className={classes.AddUserRow}>
            <p className={classes.Label}>{t('general.addUser.password')}</p>
            <input 
              className={classes.Input} 
              type="password" 
              placeholder={t('general.addUser.passwordPlaceholder')}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className={classes.ButtonRow}>
            <p 
              className={ checkData() ? [classes.AddUserButton].join(" ") : [classes.AddUserButton, classes.AddUserButtonDisabled].join(" ")}
              onClick={() => onAddUser()}
            >{t('general.addUser.add')}</p>
          </div>
        </div>
      </div>
      <img 
        className={classes.BackButton} 
        onClick={() => redirectTo("/visualization/narrations")}
        src={BACK} 
        alt="" 
      />
    </div>  
  );
  
};

export default AddUser;