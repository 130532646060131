const commonWords = [
  "di", "a", "da", "in", "con", "su", "per", "tra", "fra",
  "il", "lo", "la", "i", "gli", "le",
  "un", "uno", "una",
  "del", "dei", "dello", "della", "degli", "delle",
  "al", "ai", "allo", "alla", "agli", "alle",
  "dal", "dai", "dallo", "dalla", "dagli", "dalle",
  "nel", "nei", "nello", "nella", "negli", "nelle",
  "col", "coi",
  "sul", "sui", "sullo", "sulla", "sugli", "sulle",
  "alcuno", "alcuna", "alcuni", "alcune",
  "poiché", "quindi", "perciò", "anche",
  "chi", "come", "cosa", "dove", "quando", "perché",
  "se", "sè", "né", "è", "ce", "c'è", "n'è", "si", "sì", "sia",
  "ciò", "cioè", "anch'esso", "anch'essi", "anch'esse",
  "dov'é", "quand'é", "com'é",
  "io", "tu", "egli", "ella", "noi", "voi", "essi", "esse",
];

const charactersToDelete = [
  "un'", "all'", "dall'", "dell'", "sull'", "nell'", "?", "!", ",", ".", ":", ";"
];

const getWordsArray = (text) => {
  if (!text) {
    return [];
  }
  const words = text.split(" ").map(w => { return w.toLowerCase() });
  const notCommonWords = words.filter(w => { return !commonWords.includes(w) });
  const relevantWords = notCommonWords.map(w => {
    let word = w;
    for(const ctd of charactersToDelete){
      if(word.includes(ctd)){
        word = word.replace(ctd, "");
      }
    }
    return word;
  });
  return relevantWords;
};

export const similarityAlgorithm = (text1, text2) => {
  const words1 = getWordsArray(text1);
  const words2 = getWordsArray(text2);
  let similarWords = 0;
  const total = words1.length;
  for(const w of words1){
    if(words2.includes(w)){
      similarWords++;
    }
  }
  return similarWords / total;
};

