import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./SearchBox.module.css";


const SearchBox = (props) => {
  
  const {
    style,
    narrId,
    narrTitle,
    connectedObjects,
    onHoverStart,
    onHoverEnd,
    onClick
  } = props;

  const { t } = useTranslation();

  return (
    <div 
      className={classes.Container} 
      style={{ ...style }}
      onMouseEnter={() => onHoverStart()}
      onMouseLeave={() => onHoverEnd()}
      onClick={() => onClick()}
    >
      <div className={classes.IntestationRow}>
        <p className={classes.NarrationLabel}>{"narrazione"}</p>
      </div>
      <p className={classes.NarrationTitle}>{narrTitle}</p>
      <div className={classes.NarrationObjectContainer}>
        <p className={classes.NarrationObjectIntestation}>{"elementi simili"}</p>
        { 
          connectedObjects && connectedObjects.length > 0 &&
          connectedObjects.map((obj, index) => {
            return (
              <div key={"search-box-narration-"+narrId+"-"+index} className={classes.ConnectedObjectRow}>
                <p className={classes.ConnectedObjectType}>{obj.type}</p>
                <div className={classes.ConnectedObjectText}>{obj.text}</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );

};

export default SearchBox;