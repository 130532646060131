import React from "react";
import { useTranslation } from "react-i18next";

import Fragment from "../Fragment/Fragment";
import InsertedFragment from "../InsertedFragment/InsertedFragment";

import { paragraphs as frags } from "../../utils/constants";

import classes from './AddNarration.module.css';

const AddNarration = React.forwardRef((props, ref) => {
  
  const {
    fragments,
    title,
    author,
    existingSources,
    insertedFragments,
    insertedSources,
    onInsertTitle,
    onInsertAuthor,
    onInsertFragment,
    onRemoveFragment,
    onEditFragment,
    onMoveFragment,
    omekaSClasses,
    omekaSProperties
  } = props;

  const containerRef = React.useRef();
  const titleInputRef = React.useRef();

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({

    resetInputFields() {
      document.getElementById("narration-title-input").value = "";
      document.getElementById("narration-author-input").value = "";
    },

    checkData(title) {
      if(!title){
        titleInputRef.current.classList.add(classes.InputError);
        return false;
      }
      else{
        return true;
      }
    },

    containerRef: containerRef

  }));

  return (
    <div ref={containerRef} className={classes.Container}>
      <div className={classes.InfoContainer}>
        <p className={classes.InfoTitle}>{t('author.addNarration.add')}</p>
        <p className={[classes.InfoSubTitle, classes.Info].join(" ")}>{t('author.addNarration.info')}</p>
        <p className={classes.InfoText}>{t('author.addNarration.textInfo1')}</p>
        <p className={[classes.InfoSubTitle, classes.Fragment].join(" ")}>{t('author.addNarration.fragments')}</p>
        <p className={classes.InfoText}>{t('author.addNarration.textInfo2')}</p>
        <p className={classes.InfoText}>{t('author.addNarration.textInfo3')}</p>
      </div>
      <div className={classes.BoxesContainer}>
        <div className={[classes.Box, classes.TitleAuthorLocationBox].join(" ")}>
          <input
            ref={titleInputRef}
            id="narration-title-input"
            type="text"  
            className={[classes.Input, classes.TitleInput].join(" ")} 
            placeholder={t('author.addNarration.title')} 
            value={title}
            onFocus={() => { titleInputRef.current.classList.remove(classes.InputError); }}
            onChange={(event) => onInsertTitle(event.target.value)}
          />
          <input 
            id="narration-author-input"
            type="text" 
            className={[classes.Input, classes.AuthorInput].join(" ")} 
            placeholder={t('author.addNarration.author')}
            value={author}
            onChange={(event) => onInsertAuthor(event.target.value)}
          />
        </div>
        <div className={[classes.Box, classes.FragmentsBox].join(" ")}>
          <InsertedFragment
            omekaSClasses={omekaSClasses}
            omekaSProperties={omekaSProperties}
            existingSources={existingSources}
            insertedFragments={insertedFragments}
            insertedSources={insertedSources}
            onMoveFragment={(newFragments) => onMoveFragment(newFragments)}
            onEditFragment={(newFragment, index) => onEditFragment(newFragment, index)}
            onRemoveFragment={(index) => onRemoveFragment(index)}
          />
          {
            fragments.map((f) => {
              if(!insertedFragments.map(i => { return i.fragment }).includes(f) || f ===  frags.OTHER.label) {
                return (
                  <Fragment
                    omekaSClasses={omekaSClasses}
                    omekaSProperties={omekaSProperties}
                    existingSources={existingSources}
                    key={"unique-key-fragment-"+f}
                    insertedSources={insertedSources}
                    fragment={f}
                    onAddFragment={(fragment) => onInsertFragment(fragment)}
                  />
                )
              }
              else{
                return null;
              }
            })
          }
        </div>
      </div>
    </div>
  );

});

export default AddNarration;