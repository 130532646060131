import React from "react";

import NetworkGraph from "../Graphs/NetworkGraph/NetworkGraph";

import classes from "./Network.module.css";

const Network = (props) => {

  const {
    hoveredNarration,
    nodes,
    edges,
    renderPopup,
    onNodeEnter,
    onNodeLeave,
    onNodeClick,
    onNodeDoubleClick,
    onEdgeEnter,
    onEdgeLeave,
    onEdgeClick,
    arrow,
    direction,
    panning,
    NodeComponent,
    nodeColor,
    nodeHoverColor,
    edgeColor,
    edgeHoverColor
  } = props;

  const nodeEntered = React.useCallback((node) => {
    if(onNodeEnter){
      return onNodeEnter(node);
    }
  }, [onNodeEnter]);

  const nodeLeft = React.useCallback((node) => {
    if(onNodeLeave){
      return onNodeLeave(node);
    }
  }, [onNodeLeave]);
  
  const nodeClicked = React.useCallback((node) => {
    if(onNodeClick){
      return onNodeClick(node);
    }
  }, [onNodeClick]);

  const nodeDoubleClicked = React.useCallback((node) => {
    if(onNodeDoubleClick){
      return onNodeDoubleClick(node);
    }
  }, [onNodeDoubleClick]);

  const edgeEntered = React.useCallback((edge) => {
    if(onEdgeEnter){
      return onEdgeEnter(edge);
    }
  }, [onEdgeEnter]);

  const edgeLeft = React.useCallback((edge) => {
    if(onEdgeLeave){
      return onEdgeLeave(edge);
    }
  }, [onEdgeLeave]);
  
  const edgeClicked = React.useCallback((edge) => {
    if(onEdgeClick){
      return onEdgeClick(edge);
    }
  }, [onEdgeClick]);

  const popupRendered = React.useCallback((data) => {
    if(renderPopup){
      return renderPopup(data);
    }
  }, [renderPopup]);

  return (
    <div className={classes.Wrapper}>
      <div style={{ width: '100%', height: '100%' }}>
        <NetworkGraph
          hoveredNarration={hoveredNarration}
          nodes={nodes} 
          edges={edges}
          renderPopup={(data) => popupRendered(data)}
          onNodeEnter={(node) => nodeEntered(node)}
          onNodeLeave={(node) => nodeLeft(node)}
          onNodeClick={(node) => nodeClicked(node)}
          onNodeDoubleClick={(node) => nodeDoubleClicked(node)}
          onEdgeEnter={(edge) => edgeEntered(edge)}
          onEdgeLeave={(edge) => edgeLeft(edge)}
          onEdgeClick={(edge) => edgeClicked(edge)}
          arrow={arrow}
          direction={direction}
          panning={panning}
          nodeColor={nodeColor}
          nodeHoverColor={nodeHoverColor}
          edgeColor={edgeColor}
          edgeHoverColor={edgeHoverColor}
          NodeComponent={NodeComponent}
        />
      </div>
    </div>
  );

};

export default Network;