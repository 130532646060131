import React from "react";

import classes from "./LatLngRow.module.css";

import DELETE from "../../assets/images/delete.svg";
import EDIT from "../../assets/images/edit.svg";
import { t } from "i18next";

const LatLngRow = (props) => {
  
  const { 
    lat,
    lng,
    onEditLocation,
    onDeleteLocation
  } = props;

  const [editMode, setEditMode] = React.useState(false);
  const [editedLat, setEditedLat] = React.useState();
  const [editedLng, setEditedLng] = React.useState();

  const editLocation = React.useCallback(() => {
    setEditMode(false);
    onEditLocation({ lat: editedLat ? editedLat : lat, lng: editedLng ? editedLng : lng });
  }, [onEditLocation, editedLat, editedLng, lat, lng]);

  
  return (
    <div className={classes.Wrapper}>
      <div className={classes.CoordinatesContainer}>
        <p className={classes.Label}>lat:</p>
        {
          !editMode &&
          <p className={classes.Value}>{lat}</p>
        }
        {
          editMode &&
          <input 
            type="text" 
            defaultValue={lat}
            onChange={(e) => setEditedLat(e.target.value)}
          />
        }
        <p className={classes.Label}>lng:</p>
        {
          !editMode &&
          <p className={classes.Value}>{lng}</p>
        }
        {
          editMode &&
          <input 
            type="text" 
            defaultValue={lng}
            onChange={(e) => setEditedLng(e.target.value)}
          />
        }
      </div>
      <div className={classes.IconsContainer}>
        {
          !editMode &&
          <img
            className={classes.Icon}
            src={EDIT}
            alt=""
            title={t("author.addKnowledge.editLocation")}
            onClick={() => setEditMode(true)}
          />
        }
        {
          editMode &&
          <p
            className={classes.EditIconText}
            title={t("author.addKnowledge.confirmEditLocation")}
            onClick={() => editLocation()}
          >&#10004;</p>
        }
        {
          !editMode &&
          <img
            className={classes.Icon}
            src={DELETE}
            alt=""
            title={t("author.addKnowledge.deleteLocation")}
            onClick={() => onDeleteLocation()}
          />
        }
        {
          editMode &&
          <p
            className={classes.EditIconText}
            title={t("author.addKnowledge.confirmDeleteLocation")}
            onClick={() => setEditMode(false)}
          >&#10006;</p>
        }
      </div>
    </div>
  );

};

export default LatLngRow;