import React from "react";

import classes from "./CustomNode.module.css";

export const foreignObjectBorders = {
  x: 2,
  y: 2
};

export const CustomNode = (props) => {

  const {
    id,
    width,
    height,
    data,
    hovered,
    selected,
    NodeComponent
  } = props;

  return (
    <foreignObject 
      height={height - 2*foreignObjectBorders.y} 
      width={width - 2*foreignObjectBorders.x} 
      x={foreignObjectBorders.x} 
      y={foreignObjectBorders.y} 
      className={classes.ForeignObject}
    >
      <NodeComponent id={id} hovered={hovered} selected={selected} data={data} />
    </foreignObject>
  )

};