import React from "react";

import * as GLOBAL_STYLES from '../../utils/stylesConstants';

import USER_ICON from '../../assets/images/userAvatar.svg';
import HAMBURGER_ICON from '../../assets/images/hamburger.svg';

import classes from './TopBar.module.css';
import AuthorSaveAndOrExitButtons from "../AuthorSaveAndOrExitButtons/AuthorSaveAndOrExitButtons";

import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { roles, VIZ_TYPES, SEARCH_TYPES } from "../../utils/constants";

import DELETE from '../../assets/images/closeGrey.svg';
import DELETE_HOVERED from '../../assets/images/closePrimary.svg';
import SEARCH from '../../assets/images/searchIcon.svg';
import SEARCH_HOVERED from '../../assets/images/searchIconPrimary.svg';
import INFO_ICON from '../../assets/images/infoIcon.svg';
import FILTER_ICON from '../../assets/images/filterFilteringIcon.svg';
import FILTER_OUTLINE_ICON from '../../assets/images/filterOutlineIcon.svg';

const axios = require('axios').default;

const allowedSearchTypes = [
  SEARCH_TYPES.NARRATION,
  SEARCH_TYPES.NARRATOR,
  SEARCH_TYPES.FRAGMENT,
  SEARCH_TYPES.KNOWLEDGE,
  SEARCH_TYPES.SOURCE,
  SEARCH_TYPES.SOURCE_REFERENCE
];

const TopBar = React.forwardRef((props, ref) => {
  
  const {
    style,
    isAuthor,
    isNarrationDetail,
    isEdit,
    onDelete,
    onSaveAndQuit,
    onSave,
    onExit,
    onSearch,
    onClearSearch,
    onSetSearchTypes,
    currentSearchTypes,
    onFilterNarrations,
    narrationsFiltered,
    currentVizType
  } = props;

  const inputRef = React.useRef();

  const { userData, userLogged, setLoginData } = React.useContext(UserContext);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [searchIconHovered, setSearchIconHovered] = React.useState(false);
  const [deleteIconHovered, setDeleteIconHovered] = React.useState(false);
  const [searchInfoOpen, setSearchInfoOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [textEdited, setTextEdited] = React.useState(false);
  const [text, setText] = React.useState("");

  const getRoleString = React.useCallback((role) => {
    let roleString;
    switch(role){
      case 0:
        roleString = t("general.roles.administrator");
        break;
      case 1:
        roleString = t("general.roles.editor")
        break;
      case 2:
        roleString = t("general.roles.guest")
        break;
      default:
        roleString = t("general.roles.guest")
    }
    return roleString;
  }, [t]);

  const onClear = React.useCallback(() => {
    inputRef.current.value = "";
    onClearSearch();
  }, [inputRef, onClearSearch])

  const toggleMenu = React.useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);
  
  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const onLogout = React.useCallback(() => {
    axios.post("/api/logout").then(
      (res) => {
        console.log("LOGGED OUT", res);
        setMenuOpen(false);
        setLoginData();
        if(isAuthor){
          redirectTo("/visualization/narrations");
        }
      },
      (error) => {
        console.log("ERROR", error);
      }
    );
  }, [setLoginData, isAuthor, redirectTo]);

  const doDelayedSearch = React.useCallback(() => {
    onSearch(text);
    setLoading(false);
    setTextEdited(false);
  }, [text, onSearch]);

  const selectDeselectAll = React.useCallback(() => {
    if(currentSearchTypes.length < allowedSearchTypes.length){
      const notYetAddedSearchTypes = allowedSearchTypes.filter(el => !currentSearchTypes.includes(el));
      onSetSearchTypes(notYetAddedSearchTypes, true);
    }
    else{
      onSetSearchTypes(allowedSearchTypes, false);
    }
  }, [currentSearchTypes, onSetSearchTypes]);

  return (
    <div ref={ref} style={{ ...style }} className={[classes.TopBar, isAuthor ? classes.AuthorTopBar : ( isNarrationDetail ? classes.NarrationDetailTopBar : classes.VisualizationTopBar)].join(" ")}>
      <p className={classes.Name} style={{ color: GLOBAL_STYLES.textSecondaryColor }}>HISTORYGRAPHIA</p>
      { 
        !isAuthor && !isNarrationDetail && 
        <div className={classes.InputContainer}>
          <input
            ref={inputRef} 
            type="text" 
            placeholder="Cerca una narrazione" 
            className={classes.Input}
            onChange={(e) => {
              setTextEdited(true);
              setText(e.target.value)
            }}
            onKeyUp={(k) => {
              setText(k.target.value);
              if(k.key === "Enter"){
                setLoading(true);
                doDelayedSearch();
              }
            }}
          />
          { 
            !loading && !textEdited &&
            <img 
              src={deleteIconHovered ? DELETE_HOVERED : DELETE} 
              className={classes.DeleteSearch}
              onClick={() => onClear()}
              onMouseEnter={() => setDeleteIconHovered(true)}
              onMouseLeave={() => setDeleteIconHovered(false)}
              alt=""
            />
          }
          {
            !loading && textEdited &&
            <img 
              src={searchIconHovered ? SEARCH_HOVERED : SEARCH} 
              className={classes.DeleteSearch}
              onMouseEnter={() => setSearchIconHovered(true)}
              onMouseLeave={() => setSearchIconHovered(false)}
              onClick={() => {
                setLoading(true);
                doDelayedSearch()
              }}
              alt=""
            />
          }
          {
            loading &&
            <span className={classes.Loader}></span>
          }
          <img 
            className={classes.InfoIcon}
            src={INFO_ICON}
            alt=""
            title={t("general.search.filterSearchText")}
            onClick={() => setSearchInfoOpen(!searchInfoOpen)}
          />
          {
            currentVizType === VIZ_TYPES.NETWORK &&
            <img 
              className={classes.FilterIcon}
              src={narrationsFiltered ? FILTER_ICON : FILTER_OUTLINE_ICON}
              alt=""
              title={t("general.search.filterSearchText")}
              onClick={() => onFilterNarrations(!narrationsFiltered)}
            />
          }
          { 
            searchInfoOpen &&
            <div className={classes.FilterSearchArea}>
              <div className={classes.SearchAreaIntestation}>
                <p className={classes.SearchAreaTitle}>{t("general.search.title")}:</p>
                <p className={classes.SelectDeselectAll} onClick={() => selectDeselectAll()}>{currentSearchTypes.length < allowedSearchTypes.length ? t("general.search.selectAll") : t("general.search.deselectAll") }</p>
              </div>
              <div className={classes.SearchAreaContainer}>
                {
                  allowedSearchTypes.map(el => {
                    return (
                      <div key={"filter-search-unique-key-"+el} className={classes.SearchChoiceContainer} onClick={() => onSetSearchTypes([el], !currentSearchTypes.includes(el))}>
                        <input className={classes.SearchChoiceCheckBox} type="checkbox" checked={currentSearchTypes.includes(el)} readOnly/>
                        <p className={classes.SearchChoiceLabel}>{t("general."+el)}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div> 
          }
        </div>
      }
      {
        !isAuthor && 
        <div onClick={() => toggleMenu()} className={classes.IconButton} style={{ margin: 'auto 3rem auto auto' }}>
          <img className={classes.Icon} src={userLogged ? USER_ICON : HAMBURGER_ICON} alt="" /> 
        </div>
      }
      {
        isAuthor &&
        <AuthorSaveAndOrExitButtons 
          isEdit={isEdit}
          onDelete={onDelete}
          onSaveAndQuit={onSaveAndQuit}
          onExit={onExit}
          onSave={onSave}
        />
      }
      {
        isAuthor &&
        <div onClick={() => toggleMenu()} className={classes.IconButton} style={{ margin: 'auto 3rem auto 0' }}>
          <img className={classes.Icon} src={userLogged ? USER_ICON : HAMBURGER_ICON} alt="" />
        </div>
      }
      {
        menuOpen && 
        <div className={classes.Menu}>
          { 
            userLogged &&
            <>
              <div className={classes.InstestationRow}>
                <p className={classes.Username}>{userData.user}</p>
                <p className={classes.Role}>{getRoleString(userData.role)}</p>
              </div>
              <div className={classes.Divider}/>
              {
                userData && userData.role <= roles.ADMINISTRATOR &&
                <p 
                  className={classes.Action}
                  onClick={() => { redirectTo("/add-user")}}
                >{t(`general.routes.addUser`)}</p>
              }
              {
                userData && userData.role <= roles.EDITOR &&
                <p 
                  className={classes.Action}
                  onClick={() => { redirectTo(isAuthor ? "/visualization/narrations" : "/author") }}
                >{t(`general.routes.${isAuthor ? "goToVisualization" : "goToAuthor"}`)}</p>
              }
              <p 
                className={classes.Action} 
                onClick={() => onLogout()}
              >{t("general.routes.logout")}</p>
            </>
          }
          {
            !userLogged &&
            <>
              <p 
                className={classes.Login} 
                onClick={() => redirectTo("/login", { from: pathname })}
              >{t("general.routes.login")}</p>
            </>
          }
        </div>
      }
    </div>
  );

});

export default TopBar;