import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import * as omekaSManager from "../../utils/omekaSManager";
import { routes } from '../../components/Router/RouterManager';
import EditNarration from "./EditNarration/EditNarration";
import AddNarration from "./AddNarration/AddNarration";

const axios = require('axios').default;

const routeBaseName = routes.AUTHOR;

const Author2 = () => {
  
  return (
    <Routes>
      <Route path={`/:${routeBaseName.params.ID}`} exact element={<EditNarration />} />
      <Route path="" exact element={<AddNarration />} />
      <Route path="*" element={<Navigate to={routeBaseName.name} />} />
    </Routes>
  );

};

export default Author2;