import React from "react";
import { useTranslation } from "react-i18next";
import SourceToAdd from "../SourceToAdd/SourceToAdd";
import classes from './AddSources.module.css';
import { v4 } from "uuid";

import ADD_ICON from '../../assets/images/addLight.svg';


const AddSources = React.forwardRef((props, ref) => {
  
  const {
    existingSources,
    insertedSources,
    onInsertSource,
    onRemoveSource,
    onEditSource
  } = props;

  const containerRef = React.useRef();
  const sourcesRefs = React.useRef([]);
  sourcesRefs.current = [];

  const { t } = useTranslation();

  const handleAddSource = React.useCallback(() => {
    const newSource = {
      uuid: v4(),
      type: "",
      title: "",
      media: []
    }
    onInsertSource(newSource);  
  }, [onInsertSource]);

  const handleEditSource = React.useCallback((newSource, index) => {
    onEditSource(newSource, index);  
  }, [onEditSource]);

  const handleRemoveSource = React.useCallback((index) => {
    onRemoveSource(index);  
  }, [onRemoveSource]);

  const addSourceRef = React.useCallback((r) => {
    if(r && r.checkData && !sourcesRefs.current.includes(r)){
      sourcesRefs.current.push(r);
    }
  }, [sourcesRefs]);

  React.useImperativeHandle(ref, () => ({

    checkData() {
      let notReadySources = 0;
      for(const [i, s] of Object.entries(insertedSources)){
        const index = parseInt(i);
        const checkResult = sourcesRefs.current[index].checkData(s);
        if(!checkResult) {
          notReadySources++;
        }
      }
      return notReadySources === 0;
    },

    containerRef: containerRef

  }));

  return (
    <div ref={containerRef} className={classes.Container}>
      <div className={classes.InfoContainer}>
        <p className={classes.InfoTitle}>{t('author.addSources.add')}</p>
        <p className={[classes.InfoSubTitle, classes.Info].join(" ")}>{t('author.addSources.addSource')}</p>
        <p className={classes.InfoText}>{t('author.addSources.textInfo1')}</p>
        <p className={classes.InfoText}>{t('author.addSources.textInfo2')}</p>
      </div>
      <div className={classes.SourcesContainer}>
        {
          insertedSources.map((s, index) => {
            let source;
            if(typeof s === "string"){
              const filteredItems = existingSources.filter(el => { return el.uuid === s });
              if(filteredItems && filteredItems.length === 1){
                source = filteredItems[0];
              }
            }
            else{
              source = s;
            }
            
            if(source){
              return (
                <SourceToAdd
                  existingSources={existingSources}
                  ref={(r) => addSourceRef(r)}
                  key={"source-"+source.uuid}
                  source={source}
                  onEdit={(newSource) => handleEditSource(newSource, index)}
                  onRemove={() => handleRemoveSource(index)}
                />
              );
            }
            else{
              return null;
            }
            
          })
        }
        <div className={classes.AddSourceContainer}>
          <div className={classes.AddSourceBox} onClick={() => handleAddSource()}>
            <img className={classes.AddSourceIcon} src={ADD_ICON} alt="" />
            <p className={classes.AddSourceText}>{t('author.addSources.add')}</p>
          </div>
        </div>
      </div>
    </div>
  );

});

export default AddSources;