import React from "react";
import { useTranslation } from "react-i18next";

import classes from './AuthorSaveAndOrExitButtons.module.css';

import DELETE from '../../assets/images/delete.svg';
import Popup from "../Popup/Popup";

const POPUP_TYPES = {
  EXIT: "exit",
  DELETE: "delete"
};

const AuthorSaveAndOrExitButtons = (props) => {

  const {
    isEdit,
    onDelete,
    onExit,
    onSave,
    onSaveAndQuit,
  } = props;

  const { t } = useTranslation();

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupType, setPopupType] = React.useState();

  const handleSave = React.useCallback(() => {
    if(onSave){
      onSave();
    }
  }, [onSave]);
  
  const handleSaveAndQuit = React.useCallback(() => {
    if(onSaveAndQuit){
      onSaveAndQuit();
    }
  }, [onSaveAndQuit]);

  const handleExit = React.useCallback(() => {
    if(onExit){
      onExit();
    }
  }, [onExit]);

  const handleDelete = React.useCallback(() => {
    if(onDelete){
      onDelete();
    }
  }, [onDelete]);

  const popupConfirmed = React.useCallback(() => {
    setPopupOpen(false);
    if(popupType === POPUP_TYPES.EXIT){
      handleExit();
    }
    else if(popupType === POPUP_TYPES.DELETE){
      handleDelete();
    }
    else{} 
  }, [popupType, handleDelete, handleExit]);

  const exitButtonClicked = React.useCallback(() => {
    setPopupOpen(true);
    setPopupType(POPUP_TYPES.EXIT);
  }, []);
  
  const deleteButtonClicked = React.useCallback(() => {
    setPopupOpen(true);
    setPopupType(POPUP_TYPES.DELETE);
  }, []);
  
  return (
    <div className={classes.AuthorContainer}>
      {
        isEdit &&
        <div className={[classes.Buttons, classes.Delete].join(" ")} onClick={() => deleteButtonClicked()}>
          <p className={classes.DeleteText}>{t('author.deleteNarration')}</p>
          <img
            alt=""
            src={DELETE}
            className={classes.DeleteIcon}
          />
        </div>
      }
      <p
        style={{ marginLeft: !isEdit ? 'auto' : 0}}
        className={[classes.Buttons, classes.ExitWithoutSaving].join(" ")}
        onClick={() => exitButtonClicked()}
      >{t('author.exitWithoutSaving')}</p>
      <p 
        className={[classes.Buttons, classes.Save].join(" ")}
        onClick={() => handleSave()}
      >{t('author.save')}</p>
      <p 
        className={[classes.Buttons, classes.SaveAndQuit].join(" ")}
        onClick={() => handleSaveAndQuit()}
      >{t('author.saveAndQuit')}</p>
      {
        popupOpen &&
        <Popup>
          <p className={classes.PopupText}>{popupType === POPUP_TYPES.EXIT ? t("general.areYouSure.exit") : t("general.areYouSure.deleteNarration") } </p>
          <div className={classes.PopupConfirmContainer}>
            <p className={classes.PopupChoice} onClick={() => setPopupOpen(false)}>{t("general.areYouSure.no")}</p>
            <p className={classes.PopupChoice} onClick={() => popupConfirmed()}>{t("general.areYouSure.yes")}</p>
          </div>
        </Popup>
      }
    </div>
  );

};

export default AuthorSaveAndOrExitButtons;