import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { UserContext } from "../../contexts/UserContext";
import { FEEDBACK_STATUSES } from "../../utils/constants";

import classes from "./Login.module.css";

const axios = require('axios').default;


const Login = () => {

  const { setLoginData } = React.useContext(UserContext);
  const { setToastData } = React.useContext(ToastContext);

  const { t } = useTranslation();
  const { state: routeState } = useLocation(); 
  const navigate = useNavigate();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const onLogin = React.useCallback(() => {
    const body = { 
      username: username, 
      password: password 
    };
    axios.post("/api/login", body).then(
      (res) => {
        console.log("LOGGED IN", res);
        setToastData(t("general.feedback.login", { context: FEEDBACK_STATUSES.SUCCESS }), FEEDBACK_STATUSES.SUCCESS, 2);
        setLoginData();
        if(routeState && routeState.from){
          setTimeout(() => {
            redirectTo(routeState.from);
          }, .5*1000);
        }
        else{
          redirectTo("/visualization/narrations");
        }
      },
      (error) => {
        console.log("ERROR", error);
        setToastData(t("general.feedback.login", { context: FEEDBACK_STATUSES.ERROR }), FEEDBACK_STATUSES.ERROR, 3);
      }
    );
  }, [username, password, routeState, setLoginData, setToastData, redirectTo, t]);
  
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Container}>
        <p className={classes.Title}>HISTORYGRAPHIA</p>
        <div className={classes.LoginContainer}>
          <div className={classes.LoginRow}>
            <p className={classes.Label}>{t('general.login.username')}</p>
            <input 
              className={classes.Input} 
              type="text" 
              placeholder={t('general.login.usernamePlaceholder')}
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className={classes.LoginRow}>
            <p className={classes.Label}>{t('general.login.password')}</p>
            <input 
              className={classes.Input} 
              type="password" 
              placeholder={t('general.login.passwordPlaceholder')}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className={classes.ButtonRow}>
            <p 
              className={ !(username && password) ? [classes.LoginButton, classes.LoginButtonDisabled].join(" ") : [classes.LoginButton].join(" ")}
              onClick={() => onLogin()}
            >{t('general.login.login')}</p>
          </div>
        </div>
      </div>
    </div>  
  );
};

export default Login;