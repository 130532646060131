import React from "react";

import * as GLOBAL_STYLES from '../../utils/stylesConstants';

import classes from './BottomNavigation.module.css';

const margin = ".5rem";

const BottomNavigation = (props) => {

  const {
    activeCode,
    buttons,
    style,
    change
  } = props;

  const gridTemplateColumns = () => {
    return buttons.map(() => { return '1fr' }).join(" ");
  }

  const findButtonIndex = (code) => {
    for(const [index, btn] of buttons.entries()){
      if(btn.code === code) {
          return index;
      }
    }
    return -1;
  }

  const setActiveBtnPosition = () => {
    const index = findButtonIndex(activeCode);
    const fractPart = 100 / (buttons.length * 2);
    return (index * 2 * fractPart + fractPart).toFixed(2) + '%';
  }

  const setActiveBtnWidth = () => {
    const fractPart = 100 / (buttons.length);
    return `calc(${fractPart.toFixed(2)}% - ${margin})`;
  }
  
  return (
    <div style={{ ...style }}>
      <div className={classes.BtnContainer} style={{ gridTemplateColumns: gridTemplateColumns() }} >
        {<div className={classes.ActiveBtn} style={{ left: setActiveBtnPosition(), width: setActiveBtnWidth(), backgroundColor: GLOBAL_STYLES.primaryColor}}></div>}
        { 
          buttons.map(btn => {
            return (
              <p 
                key={btn.code} 
                className={classes.Button}
                style={{ color: activeCode === btn.code ? '#fff' : GLOBAL_STYLES.textPrimaryColor}}
                onClick={() => change(btn.code)}
              >{btn.label}</p>
            );
          })
        }
      </div>
    </div>
  );

};

export default BottomNavigation;