import Cookies from 'js-cookie';
import { decodeToken, isExpired } from "react-jwt";
import React from 'react';

export const UserContext = React.createContext();

const UserContextProvider = (props) => {
  
  const [userLogged, setUserLogged] = React.useState();
  const [userData, setUserData] = React.useState();

  const setData = React.useCallback(() => {
    const userCookie = Cookies.get('x-historygraphia-login');
    if(userCookie){
      setUserData(decodeToken(userCookie));
      setUserLogged(!isExpired(userCookie));
    }
    else{
      setUserData({});
      setUserLogged(false);
    }
  }, [])

  React.useEffect(() => {
    setData();
  }, [setData]);

  return (
    <UserContext.Provider value={{ userData: userData, userLogged: userLogged, setLoginData: setData }}>
      {props.children}
    </UserContext.Provider>
  );

};

export default UserContextProvider 
