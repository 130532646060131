import React from "react";
import { useTranslation } from "react-i18next";

import classes from './NarrationBox.module.css';

const NarrationBox = (props) => {
  
  const {
    narration,
    fragmentsNodes,
    onFragmentHover
  } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.Wrapper}>
      <div className={classes.NarrationContainer}>
        <p className={classes.NarrationType}>{t('general.narration')}</p>
        <p className={classes.NarrationTitle}>{narration.text}</p>
        <div className={classes.NarrationFragmentsContainer}>
          {
            fragmentsNodes.map((frag) => {
              return (
                <p 
                  key={"narration-"+narration.id+"-fragment-"+frag.id} 
                  className={classes.NarrationFragment}
                  onMouseEnter={() => onFragmentHover(frag.id)}
                  onMouseLeave={() => onFragmentHover()}
                >
                  {frag.data.label}
                </p>
              )
            })
          }
        </div>
        <div className={classes.NarrationTextContainer}>
          { 
            fragmentsNodes.map(el => { 
              return (  
                <p
                  key={"narration-"+narration.id+"-fragment-"+el.id}
                  className={classes.NarrationText}
                >{el.data.txt}</p>
              )
            }) 
          }
        </div>
      </div>
    </div>
  );
};

export default NarrationBox;